import { gqlRequest } from '@/api/gql/request';

import { DB_GET_INFO_STRING_QUERY } from '@/api/gql/query';

import type { IDatabasebGetInfoStringDTO } from '@/api/gql/types';
import { getCurrentDB } from '@/utils/database';

export async function getInfoString(tangoDb = getCurrentDB()) {
    const response = await gqlRequest<IDatabasebGetInfoStringDTO>(
        DB_GET_INFO_STRING_QUERY,
        {},
        { tangoDb },
    );
    return response.info;
}
