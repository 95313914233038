import { SERVER_GET_INFO } from '@/api/gql/query';
import { gqlRequest } from '@/api/gql/request';
import type { getServerInfoQueryType } from '@/api/gql/types';
import { batchedDatabaseOperation } from '@/api/utils/batchedDatabaseOperation/batchedDatabaseOperation';
import { zip } from 'radash';
import { getDbName } from '../../database/getDbName/getDbName';
import { addDevicesToNewServer } from '../addDeviceServerFromClasses/addDeviceServerFromClasses';
import { deleteOfflineServer } from '../deleteOfflineServer.ts/deleteOfflineServer';
import {
    getDeviceAttributeList,
    getDeviceAttributeProperties,
    getDevicePipeProperties,
    putPipeAndAttributeProperties,
    putProperty,
} from './utils';

async function getDeviceAndAttributeProperties(
    sourceDatabaseds: string,
    allDevices: string[],
) {
    // First we need to get all the attributes and pipes that are present in database. These are not equivalent to those
    // Defined in the classes, as it is a subset of them. These appear in database if they have properties set for them, and
    // possibly some other reason I am not aware of.
    const attributeLists = await batchedDatabaseOperation<string[]>()(
        getDeviceAttributeList(sourceDatabaseds),
        allDevices,
        'mutation', // these are gotten by executing command, so they are mutations
        'getAttributeList',
    );

    const pipeLists = await batchedDatabaseOperation<string[]>()(
        getDeviceAttributeList(sourceDatabaseds),
        allDevices,
        'mutation',
        'getPipeList',
    );
    // Then we need to get the properties of these attributes and pipes.
    const attributeProperties = await batchedDatabaseOperation<string[]>()(
        getDeviceAttributeProperties(sourceDatabaseds),
        zip(allDevices, attributeLists),
        'mutation',
        'getAttributeProperties',
    );

    const pipeProperties = await batchedDatabaseOperation<string[]>()(
        getDevicePipeProperties(sourceDatabaseds),
        zip(allDevices, pipeLists),
        'mutation',
        'getPipeProperties',
    );
    return { attributeProperties, pipeProperties };
}

export async function putServerInDatabase(
    server: string,
    destinationDatabase: string,
    removeFromSource = false,
) {
    // TODO: If there will be an option to batch requests in tangoGQL
    // or a way to query for pipes, attribute properties, pipe properties
    // then rewrite!

    const sourceDatabaseds = await getDbName();
    const destinationDatabaseds = await getDbName(destinationDatabase);
    // get all devices and their device properties from the server
    const [serverName, instance] = server.split('/');
    const rawServerInfo = await gqlRequest<getServerInfoQueryType>(
        SERVER_GET_INFO,
        { serverName, instance },
    );
    const classes = rawServerInfo.servers[0].instances[0].classes;
    const allDevices = classes
        .flatMap((classInfo) => classInfo.devices)
        .map((device) => device.name);

    const propertyInfo = classes
        .flatMap((classInfo) => classInfo.devices)
        .flatMap((device) =>
            device.properties.map(
                (property) =>
                    [device.name, property.name, property.value] as const, // as const makes TS sure, that this is tuple, not (string|string[])[]
            ),
        );
    const { attributeProperties, pipeProperties } =
        await getDeviceAndAttributeProperties(sourceDatabaseds, allDevices);
    // finally we can create a new server with all the devices and their properties
    await addDevicesToNewServer(
        server,
        classes,
        destinationDatabaseds,
        destinationDatabase,
    );
    if (propertyInfo.length > 0) {
        await batchedDatabaseOperation(destinationDatabase)(
            putProperty,
            propertyInfo,
            'mutation',
            'putDeviceProperties',
        );
    }
    await batchedDatabaseOperation(destinationDatabase)(
        putPipeAndAttributeProperties(destinationDatabaseds),
        zip(allDevices, attributeProperties, pipeProperties),
        'mutation',
        'putPipeAndAttributeProperties',
    );

    // finally, after the device server is copied, we can remove the original one.
    if (removeFromSource) {
        await deleteOfflineServer(server);
    }
}
