import { isEmpty } from 'radash';

// Context
import { useAppContext } from '@/hooks/state/useAppContext';

// Components
import { useServerList } from '@/context/ServerListContext';
import { Col } from 'react-bootstrap';
import LevelPanel from './LevelPanel/LevelPanel';

/**
 * Component for fetching and displaying servers controlled
 * by host. Servers are divided by levels
 */
const HostServers = () => {
    const { selectedHost } = useAppContext();
    const hostServerList = useServerList();

    const serverList = hostServerList[selectedHost];

    return (
        <Col>
            {isEmpty(serverList) ? (
                <p>There are no servers here</p>
            ) : (
                <LevelPanel serverList={serverList} />
            )}
        </Col>
    );
};

export default HostServers;
