// Utils
import getColorByState from '@/utils/colors';
import { Tooltip } from '@mui/material';

// SCSS
import './LevelPreview.scss';

interface ILevelPreviewProps {
    runningServers: number;
    turnedOffServers: number;
    otherServers: number;
}

/**
 * Display amount of running, turned off and servers in other state
 *
 * Running servers have green color
 * Turned off have red color
 * Servers in other states have grey color
 *
 * @param runningServers
 * @param turnedOffServers
 * @param otherServers
 */
const LevelPreview = ({
    runningServers,
    turnedOffServers,
    otherServers,
}: ILevelPreviewProps) => {
    return (
        <div className="levelPreview">
            <Tooltip title="Running servers" arrow>
                <p style={{ color: getColorByState('ON') }}>{runningServers}</p>
            </Tooltip>
            <p className="levelPreview-gap"> | </p>
            <Tooltip title="Not running servers" arrow>
                <p style={{ color: getColorByState('OFF') }}>
                    {turnedOffServers}
                </p>
            </Tooltip>
            <p className="levelPreview-gap"> | </p>
            <Tooltip title="Servers in other states" arrow>
                <p style={{ color: getColorByState('DISABLE') }}>
                    {otherServers}
                </p>
            </Tooltip>
        </div>
    );
};

export default LevelPreview;
