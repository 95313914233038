import { DialogActions, DialogTitle } from '@mui/material';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import './BranchOptions.scss';
type DeleteBranchDialogProps = {
    open: boolean;
    onClose: () => void;
    onDeleteBranch: () => void;
    branchName: string;
};

function DeleteBranchDialog({
    open,
    onClose,
    onDeleteBranch,
    branchName,
}: DeleteBranchDialogProps) {
    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Delete branch {branchName}?</DialogTitle>
            <DialogActions>
                <Button onClick={onClose}>CANCEL</Button>
                <Button
                    onClick={onDeleteBranch}
                    variant="contained"
                    color="warning"
                >
                    DELETE
                </Button>
            </DialogActions>
        </Dialog>
    );
}

export default DeleteBranchDialog;
