// Types

// Components
import DeviceList from '@/app/DeviceList/DeviceList';
import Breakpoint from '@/components/Breakpoint/Breakpoint';
import Grid from '@mui/material/Grid';
import ContentPicker from '../ContentPicker/ContentPicker';

// SCSS

import './CloudContent.scss';

/**
 * This component divide cloud ont two sides device list
 * and main content of app. Also create responsive layout
 */
const CloudContent = () => {
    return (
        <Grid container height="100%" spacing={2} paddingTop={1}>
            <Breakpoint xs={false} md>
                <Grid item md={3} className="cloudContainer-deviceList">
                    <DeviceList />
                </Grid>
            </Breakpoint>
            <Grid item xs md={9} className="cloudContainer-contentPicker">
                <ContentPicker />
            </Grid>
        </Grid>
    );
};

export default CloudContent;
