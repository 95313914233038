import { type IServerInfo } from '@/api/subscription';
import { useAllPollingStatuses } from '@/api/tango/server/polling/useAllPollingStatuses/useAllPollingStatuses';
import { useServedDevices } from '@/api/tango/server/useServedDevices/useServedDevices';
import { InputAdornment } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { z } from 'zod';
import {
    createServerInstanceMenuItem,
    type IHostServerDialogProps,
} from '../instanceDialogs';
import {
    MODES,
    ProfilerChart,
    type ProfilerChartModes,
} from './ProfilerChart/ProfilerChart';

const MIN_UPDATE_PERIOD = 1000; // ms
const numberInputSchema = z.number().int().min(MIN_UPDATE_PERIOD);

function PollingProfiler({ server, isOpen, close }: IHostServerDialogProps) {
    const [selectedDevice, setSelectedDevice] = useState<string | null>(null);
    const [selectedMode, setSelectedMode] =
        useState<ProfilerChartModes>('Duration');
    const [updatePeriod, setUpdatePeriod] = useState(0);
    const [refetchInterval, setRefetchInterval] = useState<false | number>(
        false,
    );
    const [updatePeriodValidationError, setUpdatePeriodValidationError] =
        useState('');
    const { allPollingStatues } = useAllPollingStatuses({
        deviceServer: server.name,
        refetchInterval,
    });

    const { data: servedDevices } = useServedDevices({
        deviceServer: server.name,
    });

    const pollingStatus = selectedDevice
        ? allPollingStatues[selectedDevice]
        : Object.values(allPollingStatues).flat();

    const validateUpdatePeriod = () => {
        const validationResult = numberInputSchema.safeParse(updatePeriod);
        if (validationResult.success) {
            setRefetchInterval(Number.parseInt(updatePeriod.toString()));
            setUpdatePeriodValidationError('');
        } else {
            setUpdatePeriodValidationError(
                validationResult.error.issues[0].message,
            );
        }
    };
    return (
        <Dialog
            open={isOpen}
            onClose={close}
            PaperProps={{
                style: { width: '90vw', maxWidth: '90vw' },
            }}
        >
            <DialogTitle>{`Polling profiler for ${server.name}`}</DialogTitle>

            <DialogContent style={{ paddingTop: '1rem' }}>
                {refetchInterval && (
                    <Typography variant="caption">
                        Updated every {refetchInterval} ms
                    </Typography>
                )}
                <Grid container spacing={2}>
                    <Grid item xs={5}>
                        <Autocomplete
                            value={selectedDevice}
                            onChange={(_event, device) =>
                                setSelectedDevice(device)
                            }
                            options={servedDevices || []}
                            renderInput={(params) => (
                                <TextField {...params} label="Device" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <Autocomplete
                            value={selectedMode}
                            onChange={(_event, mode) => setSelectedMode(mode)}
                            options={MODES}
                            disableClearable
                            renderInput={(params) => (
                                <TextField {...params} label="Chart" />
                            )}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <Stack direction="row" spacing={2}>
                            <TextField
                                value={updatePeriod}
                                onChange={(event) =>
                                    setUpdatePeriod(Number(event.target.value))
                                }
                                label="Update period"
                                variant="outlined"
                                type="number"
                                error={!!updatePeriodValidationError}
                                helperText={updatePeriodValidationError}
                                InputProps={{
                                    inputProps: {
                                        min: MIN_UPDATE_PERIOD,
                                    },
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            ms
                                        </InputAdornment>
                                    ),
                                    inputMode: 'numeric',
                                }}
                            />
                            <Stack direction="row" spacing={2}>
                                <Button
                                    variant="contained"
                                    color="warning"
                                    onClick={() => {
                                        setUpdatePeriod(0);
                                        setRefetchInterval(false);
                                        setUpdatePeriodValidationError('');
                                    }}
                                >
                                    CLEAR
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={validateUpdatePeriod}
                                >
                                    SET UPDATE PERIOD
                                </Button>
                            </Stack>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        {pollingStatus.length === 0 ? (
                            <Typography variant="h6">
                                No polling data available for{' '}
                                {selectedDevice ?? server.name}
                            </Typography>
                        ) : (
                            <ProfilerChart
                                pollingStatus={pollingStatus}
                                mode={selectedMode}
                            />
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>CANCEL</Button>
            </DialogActions>
        </Dialog>
    );
}
export const createPollingProfiler = (server: IServerInfo) =>
    createServerInstanceMenuItem(server, PollingProfiler, null);
