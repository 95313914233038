import { useAppContext } from '@/hooks/state/useAppContext';

// Constants
import { POLKA_VERSION } from '@/data/constants';

// Components
import Auth from '@/app/Auth/Auth';
import Breakpoint from '@/components/Breakpoint/Breakpoint';
import DeviceListDrawer from '../DeviceListDrawer/DeviceListDrawer';
import NavbarLogo from './NavbarLogo/NavbarLogo';

// Bootstrap
import { Col, Row } from 'react-bootstrap';

// SCSS
import './CloudNavbar.scss';

/**
 * Display logo, version and authentication status
 */
const CloudNavbar = () => {
    const { selectedDatabase } = useAppContext();

    const MobileDeviceDrawer = () =>
        selectedDatabase ? (
            <Breakpoint xs md={false}>
                <DeviceListDrawer />
            </Breakpoint>
        ) : null;

    return (
        <Row style={{ height: '60px' }}>
            <Col xs={12} style={{ height: '100%' }}>
                <div className="navbar">
                    <div className="navbar-left">
                        <MobileDeviceDrawer />
                        <NavbarLogo />
                    </div>
                    <div className="navbar-info">
                        <Auth />
                        <p className="navbar-version">{POLKA_VERSION}</p>
                    </div>
                </div>
            </Col>
        </Row>
    );
};

export default CloudNavbar;
