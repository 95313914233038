import { gqlRequest } from '@/api/gql/request';
import { createBatchedOperation } from '../createBatchedOperation/createBatchedOperation';

export type DatabaseCommandOutput<TOutput = unknown> = {
    [operationName: string]: {
        ok: boolean;
        message: string[];
        output: TOutput;
    };
};
// This function is curried, to be able to provide TOutput and infer TVariables at the same time.
export function batchedDatabaseOperation<TOutput>(tangoDb?: string) {
    return async function performRequest<TVariables>(
        ...args: Parameters<typeof createBatchedOperation<TVariables>>
    ) {
        const response = await gqlRequest<DatabaseCommandOutput<TOutput>>(
            createBatchedOperation(...args),
            {},
            { tangoDb },
        );
        const results = Object.values(response);
        return results.map((operation) => operation.output);
    };
}
