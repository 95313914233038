import { useEffect, useState } from 'react';

// Types
import type { IHostInfo } from '@/api/tango/database';

// Context
import { useAppContext } from '@/hooks/state/useAppContext';

// lib
import { checkIfSearchedHostInBranch, getBranchState } from './libBranch';

// Components
import BranchHeader from '../BranchHeader/BranchHeader';
import BranchHostList from '../BranchHostList/BranchHostList';

// SCSS
import './Branch.scss';

interface IBranchProps {
    branchName: string;
    branchHostList: IHostInfo[];
}

/**
 * Container component that represent Tango branch
 *
 * This component, check if host list is opened and
 *
 * consist of two other:
 * BranchHeader - display name and status of branch
 *
 * @param branchName name of the branch
 * @param branchHostList list of hosts
 */
const Branch = ({ branchName, branchHostList }: IBranchProps) => {
    const [isListOpen, setServerListOpen] = useState(false);
    const [isSearched, setSearched] = useState(false);
    const {
        search: { host: searchedHost },
    } = useAppContext();

    // Check when searchedContext changed
    useEffect(() => {
        if (checkIfSearchedHostInBranch(searchedHost, branchHostList)) {
            setSearched(true);
            setServerListOpen(true);
        } else setSearched(false);
    }, [branchHostList, searchedHost]);

    const branchState = getBranchState(branchHostList);
    return (
        <div className="branch">
            <BranchHeader
                branchName={branchName}
                branchState={branchState}
                isListOpen={isListOpen}
                setServerListOpen={setServerListOpen}
            />
            <BranchHostList
                branchHostList={branchHostList}
                isListOpen={isListOpen}
                isHostSearchedHere={isSearched}
            />
        </div>
    );
};

export default Branch;
