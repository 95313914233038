import { useDeviceData } from '@/context/DeviceData';
import { zodResolver } from '@hookform/resolvers/zod';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import { useForm } from 'react-hook-form';
import { ChangeHostSchema, type ChangeHostFormData } from './ChangeHostSchema';

import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';

import { changeHost } from '@/api/tango/host';
import { useAppContext } from '@/hooks/state/useAppContext';
import Typography from '@mui/material/Typography';
import './ChangeHost.scss';
type ChangeHostFormProps = {
    serverName: string;
    handleClose: () => void;
};

const STARTUP_LEVELS = [0, 1, 2, 3, 4, 5] as const;

function ChangeHostForm({ serverName, handleClose }: ChangeHostFormProps) {
    const { selectedHost } = useAppContext();
    const { hostList } = useDeviceData();

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = useForm<ChangeHostFormData>({
        defaultValues: {
            controlled: false,
            level: 0,
        },
        resolver: zodResolver(ChangeHostSchema),
    });

    const hostNames = hostList.map((host) => host.name);

    const handleFormSubmit = (data: ChangeHostFormData) => {
        const { hostName, controlled, level } = data;
        changeHost(serverName, hostName, selectedHost, controlled, level);
        handleClose();
    };

    return (
        <form
            id="change-host-form"
            onSubmit={handleSubmit(handleFormSubmit)}
            className="changeHost-form"
            noValidate
        >
            <Grid
                container
                justifyContent="center"
                alignItems="center"
                spacing={2}
            >
                <Grid item xs={12}>
                    <Autocomplete
                        options={hostNames}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="Host Name"
                                {...register('hostName')}
                                error={!!errors.hostName}
                                helperText={errors.hostName?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControlLabel
                        control={<Switch {...register('controlled')} />}
                        label={
                            <Typography textAlign="center">
                                Controlled by Starter
                            </Typography>
                        }
                    />
                </Grid>
                <Grid item xs={6}>
                    <FormControl fullWidth>
                        {/* 
                            Due to how MUI Select works, it's label prop does not render the label text
                            It prevents InputLabel from overlapping with the Select component outline
                            See: https://github.com/mui/material-ui/issues/22572
                        */}
                        <InputLabel>Startup Level</InputLabel>
                        <Select
                            label="Startup Level"
                            {...register('level')}
                            fullWidth
                            disabled={!watch('controlled')}
                            defaultValue={0}
                        >
                            {STARTUP_LEVELS.map((level) => (
                                <MenuItem key={level} value={level}>
                                    {level}
                                </MenuItem>
                            ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </form>
    );
}

export default ChangeHostForm;
