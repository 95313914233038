import { getCurrentDB } from '@/utils/database';
import { ping } from '../../client';
import { deleteServer } from '../deleteServer/deleteServer';

// The Astor implementation does not allow removing an online server, but tangogql does
export async function deleteOfflineServer(
    serverName: string,
    database = getCurrentDB(),
) {
    const isServerOnline = await ping(serverName, database);
    if (isServerOnline) {
        throw new Error('Cannot delete online server');
    }

    deleteServer(serverName, database);
}
