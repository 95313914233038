import { debounce } from 'radash';
import { useState } from 'react';

// Hooks
import useSearchBar from './useSearchBar/useSearchBar';
// Shared
import SearchTagList from '../SearchTagList/SearchTagList';

// MUI
import SearchIcon from '@mui/icons-material/Search';
import { IconButton, InputAdornment, TextField } from '@mui/material';

// SCSS
import { notifyError, notifySuccess } from '@/utils/notification';
import './SearchBar.scss';

/**
 * Search bar for server or host
 */
const SearchBar = () => {
    const [searchedString, setSearched] = useState('');
    const search = useSearchBar();

    // Form functions
    const changeSearchedString = (event: React.ChangeEvent<HTMLInputElement>) =>
        setSearched(event.target.value);

    const makeSearch = debounce(
        { delay: 300 },
        async (event: React.KeyboardEvent | React.MouseEvent) => {
            try {
                if (
                    event.type === 'click' ||
                    (event as React.KeyboardEvent).key === 'Enter'
                ) {
                    const message = search(searchedString);
                    notifySuccess(message);
                }
            } catch (error) {
                if (error instanceof Error) notifyError(error.message);
            }
        },
    );

    return (
        <>
            <TextField
                onChange={changeSearchedString}
                onKeyDown={makeSearch}
                size="small"
                placeholder="Search server"
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                className="searchBar-button"
                                type="submit"
                                size="medium"
                                onClick={makeSearch}
                                sx={{
                                    borderRadius: '0 50px 50px 0',
                                }}
                            >
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                fullWidth
                sx={{
                    '& .MuiOutlinedInput-root': {
                        paddingRight: 0,
                        borderRadius: '50px',
                    },
                }}
            />
            <SearchTagList />
        </>
    );
};

export default SearchBar;
