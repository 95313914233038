// API
import { restartServer } from '@/api/tango/host';
import { notifyInfo } from '@/utils/notification';

// Types
import type { IServerInfo } from '@/api/subscription';

//Context
import { useAppContext } from '@/hooks/state/useAppContext';

// Components
import { AcceptDialog } from '@/components/Dialog/Dialog';
import { useServerList } from '@/context/ServerListContext';
import { notifyError, notifySuccess } from '@/utils/notification';
import {
    createServerInstanceMenuItem,
    type IHostServerDialogProps,
} from './instanceDialogs';

const RestartServer = ({ server, isOpen, close }: IHostServerDialogProps) => {
    const { selectedHost } = useAppContext();
    const hostServers = useServerList();

    const exec = () => {
        const isServerOffline = () => {
            const currentServer = hostServers[selectedHost].find(
                (s) => s.name === server.name,
            );
            return currentServer?.state === 'FAULT';
        };

        restartServer(selectedHost, server.name, isServerOffline)
            .then((response) => {
                if (response) notifySuccess(response);
            })
            .catch((error: unknown) => {
                if (error instanceof Error) notifyError(error.message);
            });

        notifyInfo(`Restarting: ${server.name}`);

        close();
    };

    return (
        <AcceptDialog
            message={`Do you really want to restart the ${server.name}?`}
            fn={exec}
            isOpen={isOpen}
            close={close}
        />
    );
};

const createRestartServer = (server: IServerInfo) =>
    createServerInstanceMenuItem(server, RestartServer, null);

export default createRestartServer;
