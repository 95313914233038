import { ping } from '@/api/tango/client';
import { registerServer, startServer } from '@/api/tango/host';

// To start server we need to check if is already running,
// register him in host and then start server.
// TODO: Check StartDsPath property
export async function startExistingServer(host: string, server: string) {
    const isAlive = await ping(server);
    if (isAlive) throw new Error(`${server} already running`);
    await registerServer(host, server);
    await startServer(host, server);

    return 'Success';
}
