import { gqlRequestCommand } from '@/api/gql/request';

export type SetPollingParams = {
    serverName: string;
    period: number; // in milliseconds
    type?: 'attribute' | 'command';
    deviceName: string;
    polledObject: string;
};

export async function setPolling({
    period,
    serverName,
    type = 'attribute',
    deviceName,
    polledObject,
}: SetPollingParams) {
    // adds polling configuration for a specific command / attribute. If it already exists - it updates the polling period.
    const UPDATE_POLLING_COMMAND = 'UpdObjPollingPeriod';
    const ADD_POLLING_COMMAND = 'AddObjPolling';
    const commandArgs = [[period], [deviceName, type, polledObject]];
    const dserver = `dserver/${serverName}`;

    const addPollingResult = await gqlRequestCommand(
        dserver,
        ADD_POLLING_COMMAND,
        commandArgs,
    );
    if (
        addPollingResult.executeCommand.message.length >= 2 &&
        addPollingResult.executeCommand.message[1] === 'API_AlreadyPolled'
    ) {
        const updatePollingResult = await gqlRequestCommand(
            dserver,
            UPDATE_POLLING_COMMAND,
            commandArgs,
        );

        if (!updatePollingResult.executeCommand.ok) {
            throw new Error(
                updatePollingResult.executeCommand.message.join('\n'),
            );
        }
    } else if (!addPollingResult.executeCommand.ok) {
        throw new Error(addPollingResult.executeCommand.message.join('\n'));
    }
}
