import { BREAKPOINTS } from '@/data/constants';
import { useEffect, useState } from 'react';

interface IUseWindow {
    size: number;
    xs: boolean;
    sm: boolean;
    md: boolean;
    lg: boolean;
    xl: boolean;
}

/**
 * Check size of the window and determine which bootstrap
 * breakpoint have been hit
 */
const useWindow = (): IUseWindow => {
    const [size, setSize] = useState(window.innerWidth);

    useEffect(() => {
        function handleWindowResize() {
            setSize(window.innerWidth);
        }

        window.addEventListener('resize', handleWindowResize);

        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);

    const hit = (x: number, min: number) => x >= min;
    return {
        size: size,
        xs: hit(size, BREAKPOINTS.xs),
        sm: hit(size, BREAKPOINTS.sm),
        md: hit(size, BREAKPOINTS.md),
        lg: hit(size, BREAKPOINTS.lg),
        xl: hit(size, BREAKPOINTS.xl),
    };
};

export default useWindow;
