import { useQuery } from '@tanstack/react-query';
import { getAllPollingStatuses } from '../getAllPollingStatuses/getAllPollingStatuses';
import { extractPolledObjectInfo } from '../utils';

type UseAllPollingStatusesOptions = {
    deviceServer: string;
    refetchInterval?: number | false;
};

export function useAllPollingStatuses({
    deviceServer,
    refetchInterval,
}: UseAllPollingStatusesOptions) {
    const allPollingStatuesQuery = useQuery({
        queryKey: ['getAllPollingStatuses', deviceServer],
        queryFn: () => getAllPollingStatuses({ deviceServer }),
        enabled: !!deviceServer,
        refetchInterval: refetchInterval,
    });
    // transformation { [device: string]: string[] } -> { [device: string] : PolledObjectInfo[] }
    // so that data is already processed and indexed by device names.
    const allPollingStatues = Object.fromEntries(
        Object.entries(allPollingStatuesQuery.data ?? {}).map(
            ([device, rawPollingStatuses]) => [
                device,
                rawPollingStatuses.map((rawPollingStatus) =>
                    extractPolledObjectInfo(rawPollingStatus, device),
                ),
            ],
        ),
    );
    return { allPollingStatues, ...allPollingStatuesQuery };
}
