import { MAX_LEVELS } from '@/data/constants';
import type { ILevelList } from '../LevelPanel/libLevelPanel';

/**
 * Return level indexes in columns
 *
 * @param levelMap - list of all levels
 * @param columnAmount - number of columns
 *
 * @returns array of index array
 */
export function countColumnLevelIndex(
    levelMap: ILevelList,
    columnAmount: number,
): number[][] {
    const levelIndexes = Object.keys(levelMap);
    const totalLevels = levelIndexes.length;

    // If number of servers is smaller then number of columns
    // then we have less columns
    const columns = totalLevels > columnAmount ? columnAmount : totalLevels;

    const MAX_IN_LEVEL = MAX_LEVELS / columns;

    const indexArray = [];
    for (let colIndex = 0; colIndex < columns; colIndex++) {
        const levelsInCol: number[] = [];
        for (let levelIndex = 0; levelIndex < MAX_IN_LEVEL; levelIndex++) {
            const levelKeyIndex = colIndex + levelIndex * columns;

            if (levelKeyIndex >= totalLevels) break;
            else levelsInCol.push(Number(levelIndexes[levelKeyIndex]));
        }
        indexArray.push(levelsInCol);
    }

    return indexArray;
}
