export interface IDurationTime {
    days: number;
    hours: number;
    minutes: number;
    seconds: number;
}

/**
 * Convert number of seconds to the number of
 * days, hours, minutes and seconds
 *
 * @param seconds Number of seconds we want to convert
 *
 * @returns The object with the number of days, hours...
 */
export function convertSecondsToDuration(seconds: number): IDurationTime {
    const days = Math.floor(seconds / (24 * 3600));
    seconds %= 24 * 3600;
    const hours = Math.floor(seconds / 3600);
    seconds %= 3600;
    const minutes = Math.floor(seconds / 60);
    seconds %= 60;

    return { days, hours, minutes, seconds };
}
