import { flexRender, type ColumnDef, type Table } from '@tanstack/react-table';

// Components
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import MuiTable from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

/** Default number of rows to display per page */
export const DEFAULT_PAGE_SIZE = 10;

/** Available options for page size selection */
export const pageSizes = [10, 20, 50] as const;

interface IBaseTableProps<T extends object> {
    /** TanStack table instance that manages the table state and operations */
    table: Table<T>;
    /** Column definitions specifying how to render and handle each column */
    columns: ColumnDef<T>[];
    /** Flag indicating if the table is in a loading state */
    isLoading?: boolean;
    /** Total number of rows across all pages (used for server-side pagination) */
    totalRows?: number;
    /** Message to display when there are no rows in the table */
    emptyMessage?: string;
}

/**
 * Base table component that provides the foundation for both auto and manually paginated tables.
 * Implements Material-UI styling and handles common table functionality like sorting and pagination.
 */
const BaseTable = <T extends object>(props: IBaseTableProps<T>) => {
    const renderTableHeader = () => (
        <TableHead>
            {props.table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                    {headerGroup.headers.map((header) => (
                        <TableCell
                            key={header.id}
                            sortDirection={header.column.getIsSorted() || false}
                        >
                            {header.column.getCanSort() ? (
                                <TableSortLabel
                                    active={
                                        header.column.getIsSorted() !== false
                                    }
                                    direction={
                                        header.column.getIsSorted() === 'desc'
                                            ? 'desc'
                                            : 'asc'
                                    }
                                    onClick={header.column.getToggleSortingHandler()}
                                >
                                    {flexRender(
                                        header.column.columnDef.header,
                                        header.getContext(),
                                    )}
                                </TableSortLabel>
                            ) : (
                                flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                )
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </TableHead>
    );

    const renderTableBody = () => {
        if (props.isLoading) {
            return (
                <TableRow>
                    <TableCell
                        colSpan={props.columns.length}
                        align="center"
                        sx={{ py: 3 }}
                    >
                        <CircularProgress />
                    </TableCell>
                </TableRow>
            );
        }

        if (props.table.getRowModel().rows.length === 0) {
            return (
                <TableRow>
                    <TableCell
                        colSpan={props.columns.length}
                        align="center"
                        sx={{ py: 3 }}
                    >
                        <Typography variant="body1" color="text.secondary">
                            {props.emptyMessage || 'No data available'}
                        </Typography>
                    </TableCell>
                </TableRow>
            );
        }

        return props.table.getRowModel().rows.map((row) => (
            <TableRow key={row.id}>
                {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                        {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext(),
                        )}
                    </TableCell>
                ))}
            </TableRow>
        ));
    };

    const handlePageInput = (event: React.ChangeEvent<HTMLInputElement>) => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0;
        if (page >= 0 && page < props.table.getPageCount()) {
            props.table.setPageIndex(page);
        }
    };

    const renderPaginationControls = () => (
        <Grid container spacing={0.5}>
            <Grid item>
                <Button
                    variant="outlined"
                    size="small"
                    sx={{ minWidth: '32px' }}
                    onClick={() => props.table.firstPage()}
                    disabled={
                        !props.table.getCanPreviousPage() || props.isLoading
                    }
                >
                    {'<<'}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => props.table.previousPage()}
                    disabled={
                        !props.table.getCanPreviousPage() || props.isLoading
                    }
                >
                    {'<'}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant="outlined"
                    size="small"
                    onClick={() => props.table.nextPage()}
                    disabled={!props.table.getCanNextPage() || props.isLoading}
                >
                    {'>'}
                </Button>
            </Grid>
            <Grid item>
                <Button
                    variant="outlined"
                    size="small"
                    sx={{ minWidth: '32px' }}
                    onClick={() => props.table.lastPage()}
                    disabled={!props.table.getCanNextPage() || props.isLoading}
                >
                    {'>>'}
                </Button>
            </Grid>
        </Grid>
    );

    const renderPageInput = () => (
        <Grid container spacing={0.5} alignItems="center">
            <Grid item>
                <Typography variant="caption">Go to page:</Typography>
            </Grid>
            <Grid item>
                <TextField
                    size="small"
                    type="number"
                    defaultValue={
                        props.table.getState().pagination.pageIndex + 1
                    }
                    onChange={handlePageInput}
                    disabled={props.isLoading}
                    inputProps={{
                        min: 1,
                        max: props.table.getPageCount(),
                        style: { width: '45px' },
                    }}
                    sx={{ '& .MuiOutlinedInput-root': { height: '32px' } }}
                />
            </Grid>
        </Grid>
    );

    const renderPageSizeSelector = () => (
        <Grid container spacing={0.5} alignItems="center">
            <Grid item>
                <Select
                    size="small"
                    value={props.table.getState().pagination.pageSize}
                    onChange={(e) =>
                        props.table.setPageSize(Number(e.target.value))
                    }
                    disabled={props.isLoading}
                    sx={{ height: '32px', minWidth: '100px' }}
                >
                    {pageSizes.map((pageSize) => (
                        <MenuItem key={pageSize} value={pageSize}>
                            Show {pageSize}
                        </MenuItem>
                    ))}
                </Select>
            </Grid>
        </Grid>
    );

    const renderTableStats = () => (
        <Typography variant="caption">
            {props.totalRows !== undefined
                ? `Showing ${props.table.getRowModel().rows.length} of ${
                      props.totalRows
                  } rows`
                : `Showing ${props.table.getRowModel().rows.length} of ${
                      props.table.getPrePaginationRowModel().rows.length
                  } rows`}
        </Typography>
    );

    return (
        <Box
            sx={{
                position: 'relative',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
            }}
        >
            <TableContainer sx={{ flexGrow: 1, overflow: 'auto' }}>
                <MuiTable size="small">
                    {renderTableHeader()}
                    <TableBody>{renderTableBody()}</TableBody>
                </MuiTable>
            </TableContainer>
            <Box
                sx={{
                    position: 'sticky',
                    bottom: 0,
                    bgcolor: 'background.paper',
                    borderColor: 'divider',
                    p: 0.5,
                }}
            >
                <Grid
                    container
                    spacing={1}
                    alignItems="center"
                    justifyContent="center"
                >
                    <Grid item>{renderPaginationControls()}</Grid>
                    <Grid item>
                        <Typography variant="caption">
                            Page{' '}
                            {props.table.getState().pagination.pageIndex + 1} of{' '}
                            {props.table.getPageCount()}
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ height: 20 }}
                        />
                    </Grid>
                    <Grid item>{renderPageInput()}</Grid>
                    <Grid item>{renderPageSizeSelector()}</Grid>
                    <Grid item>
                        <Divider
                            orientation="vertical"
                            flexItem
                            sx={{ height: 20 }}
                        />
                    </Grid>
                    <Grid item>{renderTableStats()}</Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default BaseTable;
