import { useQuery } from '@tanstack/react-query';
import { getServedDevices } from '../getServedDevices/getServedDevices';

type UseServedDevicesOptions = {
    deviceServer: string;
};

export function useServedDevices({ deviceServer }: UseServedDevicesOptions) {
    return useQuery({
        queryKey: ['getServedDevices', deviceServer],
        queryFn: () => getServedDevices(deviceServer),
        enabled: !!deviceServer,
    });
}
