import type { Dispatch, SetStateAction } from 'react';
import React, { createContext, useContext, useState } from 'react';

interface IAppContextType {
    selectedDatabase: string | null;
    setSelectedDatabase: Dispatch<SetStateAction<string | null>>;
    selectedHost: string;
    setSelectedHost: Dispatch<SetStateAction<string>>;
    selectedTab: string;
    setSelectedTab: Dispatch<SetStateAction<string>>;
    search: {
        server: string;
        host: string;
    };
    setSearch: Dispatch<SetStateAction<{ server: string; host: string }>>;
}

const defaultValues: IAppContextType = {
    selectedDatabase: null,
    setSelectedDatabase: () => null,
    selectedHost: 'Database',
    setSelectedHost: () => null,
    selectedTab: 'Info',
    setSelectedTab: () => null,
    search: {
        server: '',
        host: '',
    },
    setSearch: () => null,
};

export const AppContext = createContext<IAppContextType>(defaultValues);

interface IAppContextProviderProps {
    children: React.ReactNode;
    mockValues?: Partial<IAppContextType>;
}

export const AppContextProvider: React.FC<IAppContextProviderProps> = ({
    children,
    mockValues = {}, // for testing purposes
}) => {
    const [selectedDatabase, setSelectedDatabase] = useState(
        mockValues.selectedDatabase ?? defaultValues.selectedDatabase,
    );
    const [selectedHost, setSelectedHost] = useState(
        mockValues.selectedHost ?? defaultValues.selectedHost,
    );
    const [selectedTab, setSelectedTab] = useState(
        mockValues.selectedTab ?? defaultValues.selectedTab,
    );
    const [search, setSearch] = useState(
        mockValues.search ?? defaultValues.search,
    );

    return (
        <AppContext.Provider
            value={{
                selectedDatabase,
                setSelectedDatabase,
                selectedHost,
                setSelectedHost,
                selectedTab,
                setSelectedTab,
                search,
                setSearch,
            }}
        >
            {children}
        </AppContext.Provider>
    );
};

export const useAppContext = () => {
    const context = useContext(AppContext);
    if (context === undefined) {
        throw new Error('useAppContext must be used within an AppProvider');
    }
    return context;
};
