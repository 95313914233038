import { gqlRequestCommand } from '@/api/gql/request';
import type { getServerInfoQueryType } from '@/api/gql/types';
import { createDeviceWithTangoDBFullPath } from '@/utils/database';

type ClassInfo =
    getServerInfoQueryType['servers'][number]['instances'][number]['classes'][number];

export async function addDevicesToNewServer(
    server: string,
    classes: ClassInfo[],
    databaseds: string,
    tangoDb: string,
) {
    // The DbAddServer command requires format of the argin:
    // [serverName, deviceName1, className1, deviceName2, className2, ...]
    const argin = [server];
    for (const classInfo of classes) {
        for (const device of classInfo.devices) {
            argin.push(device.name);
            argin.push(classInfo.name);
        }
    }
    try {
        await gqlRequestCommand(databaseds, 'DbAddServer', argin, {
            tangoDb,
        });
    } catch (error) {
        throw new Error(
            `Error occured when trying to add server ${createDeviceWithTangoDBFullPath(
                tangoDb,
                server,
            )}`,
        );
    }
}
