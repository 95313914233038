import type { IFetchLogsResponseDTO, ILog } from '@/api/mongo/logs/types';
import ManuallyPaginatedTable from '@/components/Table/ManuallyPaginatedTable';

import { type ColumnDef, type PaginationState } from '@tanstack/react-table';
import { useMemo, useState } from 'react';

interface ILogViewerProps {
    response: IFetchLogsResponseDTO;
    fetchHostLogs: (page: number, pageSize: number) => Promise<void>;
    isLoading: boolean;
}

const DEFAULT_PAGE_SIZE = 10;

const LogViewer = ({ response, fetchHostLogs, isLoading }: ILogViewerProps) => {
    const [currentPageSize, setCurrentPageSize] = useState(DEFAULT_PAGE_SIZE);
    const totalLogs = response.device?.totalRecordCount ?? 0;
    const logs = response.device?.userActions ?? [];
    const pageCount = Math.ceil(totalLogs / currentPageSize);

    const columns = useMemo<ColumnDef<ILog>[]>(
        () => [
            {
                accessorKey: 'timestamp',
                header: 'Time',
                sortDescFirst: true,
            },
            {
                accessorKey: 'user',
                header: 'User',
                sortUndefined: 'last',
            },
            {
                accessorKey: 'actionType',
                header: 'Action type',
                sortUndefined: 'last',
            },
            {
                accessorKey: 'name',
                header: 'Command',
                sortUndefined: 'last',
            },
            {
                accessorKey: 'argin',
                header: 'Arguments',
                sortUndefined: 'last',
            },
        ],
        [],
    );

    const handlePaginationChange = (pagination: PaginationState) => {
        setCurrentPageSize(pagination.pageSize);
        fetchHostLogs(pagination.pageIndex + 1, pagination.pageSize);
    };

    return (
        <ManuallyPaginatedTable<ILog>
            columns={columns}
            data={logs}
            pageCount={pageCount}
            totalRows={totalLogs}
            onPaginationChange={handlePaginationChange}
            isLoading={isLoading}
        />
    );
};

export default LogViewer;
