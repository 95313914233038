import { sleep } from 'radash';
import { useEffect } from 'react';

import { DB_GET_ALL_DB_TIMING } from '@/data/constants';
import { useDbContext } from './useDbContext';

interface IHostProviderProps {
    children: JSX.Element;
}

export const DbProvider = ({ children }: IHostProviderProps) => {
    const { fetchDb } = useDbContext();

    // TODO: We could create some kind of subscripotion class for that
    useEffect(() => {
        const startRequestLoop = async () => {
            for (;;) await sleep(DB_GET_ALL_DB_TIMING).then(() => fetchDb());
        };

        fetchDb();
        startRequestLoop();
    }, []);

    return children;
};
