import { useAppContext } from '@/hooks/state/useAppContext';
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { InvalidDatabaseErrorPage } from './InvalidDatabaseErrorPage/InvalidDatabaseErrorPage';

type DatabaseRouteGuardProps = {
    children: React.ReactNode;
};

/**
 * A component that guards routes based on database validation.
 * It checks if the database parameter in the URL is valid against the configured list of databases
 * and updates the database context.
 */
export function DatabaseRouteGuard({ children }: DatabaseRouteGuardProps) {
    const { databaseName } = useParams();
    const { setSelectedDatabase } = useAppContext();
    const availableDatabases = window.POLKA_CONFIG?.databases || [];

    useEffect(() => {
        const isValidDatabase =
            !databaseName || availableDatabases.includes(databaseName);
        if (isValidDatabase) {
            setSelectedDatabase(databaseName || null);
        }
    }, [databaseName, availableDatabases, setSelectedDatabase]);

    if (databaseName && availableDatabases.includes(databaseName)) {
        return <>{children}</>;
    }

    return (
        <InvalidDatabaseErrorPage
            databases={availableDatabases}
            currentDatabase={databaseName}
        />
    );
}
