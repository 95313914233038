import Loading from '@/components/Loading/Loading';
import { useCallback } from 'react';

// API
import * as TangoHost from '@/api/tango/host';
import { useFetch } from '@/hooks/useFetch';

// Context
import { useAppContext } from '@/hooks/state/useAppContext';

/**
 * Display information about host
 * Information looks like this:
 *
 * Device: dserver/starter/tangobox
 * State: ON
 * Exported: true
 * Connected: true
 * Server: Starter/tangobox
 * Host: tangobox
 * PID: 2306
 * Start date: 15th June 2023 at 08:57:10
 * Stop date: 22nd May 2023 at 15:11:53
 */
const HostInfo = () => {
    const { selectedHost } = useAppContext();

    const getInfo = useCallback(
        () => TangoHost.getInfo(selectedHost),
        [selectedHost],
    );
    const { value: hostInfo, loading, error } = useFetch(getInfo);
    if (error) return <p>Error: {error.message}</p>;
    else if (loading) return <Loading />;
    else {
        const BasicData = (
            <>
                <p>Device: {hostInfo.name}</p>
                <p>State: {hostInfo.state}</p>
                <p>Exported: {hostInfo.exported.toString()}</p>
            </>
        );

        if (hostInfo.state === 'ON')
            return (
                <div>
                    {BasicData}
                    <p>Connected: {hostInfo.connected.toString()}</p>
                    <p>Server: {hostInfo.server.id}</p>
                    <p>Host: {hostInfo.server.host}</p>
                    <p>PID: {hostInfo.pid}</p>
                    <p>Start date: {hostInfo.startedDate}</p>
                    <p>Stop date: {hostInfo.stoppedDate}</p>
                </div>
            );
        else return <div>{BasicData}</div>;
    }
};

export default HostInfo;
