// Types
import type { stateType } from '@/api/tango/types';

// MUI
import MoreVertIcon from '@mui/icons-material/MoreVert';
import IconButton from '@mui/material/IconButton';
// SCSS
import RequireAuth from '@/app/RequireAuth/RequireAuth';
import { useSession } from '@/context/Session';
import { useState } from 'react';
import DeviceListItem from '../../DeviceListItem/DeviceListItem';
import BranchOptions from '../BranchOptions/BranchOptions';
import './BranchHeader.scss';
interface IBranchHeaderProps {
    branchName: string;
    branchState: stateType;
    isListOpen: boolean;
    setServerListOpen: (open: boolean) => void;
}

/**
 * Header of the branch
 *
 * The core function of this component is to give information
 * about branch and give ability to open list of hosts.
 *
 * @param branchName
 * @param branchState
 * @param isListOpen
 * @param setServerListOpen function that change list open state
 */
const BranchHeader = ({
    branchName,
    branchState,
    isListOpen,
    setServerListOpen,
}: IBranchHeaderProps) => {
    const [anchorElement, setAnchorElement] = useState<null | HTMLElement>(
        null,
    );
    const { isLoggedIn } = useSession();
    const open = Boolean(anchorElement);
    // Couldn't we put it in Branch component and cut some function arguments?
    const toggleListStatus = () => setServerListOpen(!isListOpen);
    const handleClose = () => {
        setAnchorElement(null);
    };

    return (
        <div className="branchHeader">
            <DeviceListItem
                text={branchName}
                onClick={toggleListStatus}
                state={branchState}
                flatRightBorder={isLoggedIn}
            />
            <RequireAuth>
                <IconButton
                    onClick={(event) => {
                        event.stopPropagation();
                        setAnchorElement(event.currentTarget.parentElement);
                    }}
                    className="branchHeader-flat-border-left"
                >
                    <MoreVertIcon />
                </IconButton>
            </RequireAuth>
            <RequireAuth>
                <BranchOptions
                    open={open}
                    anchorEl={anchorElement}
                    onClose={handleClose}
                    branchName={branchName}
                />
            </RequireAuth>
        </div>
    );
};

export default BranchHeader;
