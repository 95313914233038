// API
import { getInfoString } from '@/api/tango/database';
import Loading from '@/components/Loading/Loading';
import { useFetch } from '@/hooks/useFetch';
/**
 * Display inforamtion about database
 *
 * Inforamtion looks like this:
 *
 * TANGO Database sys/database/2
 * Running since 2023-06-14 13:27:36
 * Devices defined = 26
 * Devices exported = 21
 * Device servers defined = 13
 * Device servers exported = 11
 * Device properties defined = 52 [History lgth = 108]
 * Class properties defined = 79 [History lgth = 113]
 * Device attribute properties defined = 0 [History lgth = 0]
 * Class attribute properties defined = 0 [History lgth = 0]
 * Object properties defined = 0 [History lgth = 0]
 */
const DbInfo = () => {
    const { value, error, loading } = useFetch(() => getInfoString());
    if (error) return <p>{error.message}</p>;
    else if (loading) return <Loading />;
    else
        return (
            <div>
                {value.split('\n').map((line: string, index: number) => {
                    return <p key={index}>{line}</p>;
                })}
            </div>
        );
};

export default DbInfo;
