// Context
import { useAppContext } from '@/hooks/state/useAppContext';

// API
import type { IServerInfo } from '@/api/subscription';

// Components
import StatusDot from '@/components/StatusDot/StatusDot';
import ServerInstanceMenu from './ServerInstanceMenu/ServerInstanceMenu';

// MUI
import RequireAuth from '@/app/RequireAuth/RequireAuth';
import ListItem from '@mui/material/ListItem/ListItem';

// SCSS
import './ServerInstance.scss';

interface IServerInstanceProps {
    serverInfo: IServerInfo;
}

/**
 * Print server name, status and menu with options
 *
 * @param serverInfo information about particular server
 */
const ServerInstance = ({ serverInfo }: IServerInstanceProps) => {
    const serverName = serverInfo.name;
    const serverState = serverInfo.state;

    const {
        search: { server: searchedServer },
    } = useAppContext();

    const searchedStyle =
        serverName === searchedServer ? 'serverInstance-searched' : '';

    return (
        <ListItem
            aria-selected={serverName === searchedServer}
            aria-label={serverName}
            role="listitem"
            className={`serverInstance ${searchedStyle}`}
        >
            <StatusDot state={serverState} size="small" />
            <p>{serverName}</p>
            <RequireAuth>
                <ServerInstanceMenu serverInfo={serverInfo} />
            </RequireAuth>
        </ListItem>
    );
};

export default ServerInstance;
