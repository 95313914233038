import { addHost } from '@/api/tango/host/addHost/addHost';
import { zodResolver } from '@hookform/resolvers/zod';
import Button from '@mui/material/Button';
import ButtonGroup from '@mui/material/ButtonGroup';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import HostChangeBranch from '../HostChangeBranch/HostChangeBranch';
import PathsInput from '../HostEditProperties/PathsInput/PathsInput';
import { addHostSchema, type AddHostFormData } from './AddHostFormSchema';

const defaultValues: AddHostFormData = {
    paths: new Set(),
    usage: '',
    branch: '',
    name: '',
};

function AddHostForm() {
    const {
        register,
        handleSubmit,
        setValue,
        control,
        reset,
        formState: { errors, isSubmitSuccessful },
    } = useForm<AddHostFormData>({
        defaultValues,
        resolver: zodResolver(addHostSchema),
    });

    const onSubmit = (data: AddHostFormData) => {
        addHost(data);
    };

    useEffect(() => {
        reset(defaultValues);
    }, [isSubmitSuccessful]);

    return (
        <form
            className="addHostForm"
            onSubmit={handleSubmit(onSubmit)}
            onKeyDown={(event) => {
                if (event.key === 'Enter') {
                    event.preventDefault();
                }
            }}
        >
            <Grid container spacing={2} padding={1}>
                <Grid item xs={12} md={4}>
                    <Controller
                        control={control}
                        name="branch"
                        render={({
                            field: { value },
                            formState: { errors },
                        }) => (
                            <HostChangeBranch
                                branch={value}
                                onChangeBranch={(newBranch) =>
                                    setValue('branch', newBranch)
                                }
                                error={errors.branch?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid item xs={12} md={4} key={'name'}>
                    <TextField
                        {...register('name')}
                        label={'name'}
                        error={!!errors['name']}
                        helperText={errors['name']?.message}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} md={4} key={'usage'}>
                    <TextField
                        {...register('usage')}
                        label={'usage'}
                        error={!!errors['usage']}
                        helperText={errors['usage']?.message}
                        fullWidth
                    />
                </Grid>

                <Grid item xs={12}>
                    <Controller
                        control={control}
                        name="paths"
                        render={({
                            field: { value },
                            formState: { errors },
                        }) => (
                            <PathsInput
                                paths={value}
                                onChangePaths={(newPaths) =>
                                    setValue('paths', newPaths)
                                }
                                error={errors.paths?.message}
                            />
                        )}
                    />
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={2}
                    sx={{
                        marginLeft: 'auto',
                    }}
                >
                    <ButtonGroup
                        orientation="vertical"
                        variant="contained"
                        fullWidth
                    >
                        <Button
                            type="submit"
                            variant="contained"
                            color="primary"
                        >
                            ADD
                        </Button>
                    </ButtonGroup>
                </Grid>
            </Grid>
        </form>
    );
}

export default AddHostForm;
