import { sleep } from 'radash';

import { startServer, stopServer } from '@/api/tango/host';

const RESTART_CONFIG = {
    MAX_ATTEMPTS: 10,
    RETRY_DELAY_MS: 3000,
} as const;

// Restart particular server
export async function restartServer(
    host: string,
    serverToRestart: string,
    isServerOffline: () => boolean,
) {
    try {
        await stopServer(host, serverToRestart);

        for (
            let attempt = 1;
            attempt <= RESTART_CONFIG.MAX_ATTEMPTS;
            attempt++
        ) {
            const isOffline = isServerOffline();

            if (isOffline) {
                return await startServer(host, serverToRestart);
            }

            await sleep(RESTART_CONFIG.RETRY_DELAY_MS);
        }

        throw new Error(
            `Failed to restart server "${serverToRestart}" after ${RESTART_CONFIG.MAX_ATTEMPTS} attempts`,
        );
    } catch (error) {
        throw new Error(
            `Server restart failed: ${
                error instanceof Error ? error.message : 'Unknown error'
            }`,
        );
    }
}
