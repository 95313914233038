import type { IDatabaseGetServersDTO } from '@/api/gql/types';
import { extractStateFromRawString } from '@/api/utils/stateUtils';
import type { IServerInstance } from './getAllInstances';

export function getAllInstancesCreateReadable(dto: IDatabaseGetServersDTO) {
    const normalizedInstanceList: IServerInstance[] = [];

    // Iterate through allServers
    for (const server of dto.allServers) {
        // Iterate through server instances
        for (const instanceInfo of server.instances) {
            const instanceState = extractStateFromRawString(
                instanceInfo?.classes[0]?.devices[0]?.state,
            );

            const instanceServer = instanceInfo?.classes[0]?.devices[0]?.server;

            const instanceHost =
                !instanceServer || instanceServer === null
                    ? null
                    : instanceServer.host;

            const normalizedInstanceInfo = {
                server: instanceInfo?.server ?? '',
                name: instanceInfo?.name ?? '',
                state: instanceState,
                host: instanceHost,
            };

            normalizedInstanceList.push(normalizedInstanceInfo);
        }
    }
    return normalizedInstanceList;
}
