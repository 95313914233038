import { useAppContext } from '@/hooks/state/useAppContext';

const useChangeHost = () => {
    const { setSelectedHost, setSelectedTab } = useAppContext();

    return (newHost: string, newTab: string) => {
        setSelectedHost(newHost);
        setSelectedTab(newTab);
    };
};

export default useChangeHost;
