import type { PolledObjectInfo } from '@/api/tango/server/polling/usePolling/usePolling';
import {
    Bar,
    BarChart,
    CartesianGrid,
    Cell,
    Label,
    ResponsiveContainer,
    Tooltip,
    YAxis,
} from 'recharts';
import { PollingProfilerTooltip } from '../PollingProfilerTooltip/PollingProfilerTooltip';
import './ProfilerChart.scss';
const colors = [
    'hsl(0, 100%, 50%)',
    'hsl(30, 100%, 50%)',
    'hsl(60, 100%, 50%)',
    'hsl(90, 100%, 50%)',
    'hsl(120, 100%, 50%)',
    'hsl(150, 100%, 50%)',
    'hsl(180, 100%, 50%)',
    'hsl(210, 100%, 50%)',
    'hsl(240, 100%, 50%)',
    'hsl(270, 100%, 50%)',
    'hsl(300, 100%, 50%)',
    'hsl(330, 100%, 50%)',
] as const;

export const MODES = [
    'Duration',
    'Drifts',
    // 'History', TODO implement this mode.
] as const;
export type ProfilerChartModes = (typeof MODES)[number];

type ProfilerChartConfig = {
    yAxisDataKey: keyof PolledObjectInfo;
    yAxisTopLabel?: string;
    yAxisBottomLabel?: string;
    unit: string;
};

const profilerChartConfigs: Record<ProfilerChartModes, ProfilerChartConfig> = {
    Duration: {
        yAxisDataKey: 'lastReadingDuration',
        unit: 'ms',
    },
    Drifts: {
        yAxisDataKey: 'lastDrift',
        yAxisBottomLabel: 'Polling early',
        yAxisTopLabel: 'Polling late',
        unit: 'ms',
    },
};

type ProfilerChartProps = {
    pollingStatus: PolledObjectInfo[];
    mode?: ProfilerChartModes;
};

export function ProfilerChart({
    pollingStatus,
    mode = 'Duration',
}: ProfilerChartProps) {
    const { yAxisDataKey, unit, yAxisBottomLabel, yAxisTopLabel } =
        profilerChartConfigs[mode];
    return (
        // aspect === 3 means that the chart will have a 3:1 width:height aspect ratio.
        <ResponsiveContainer
            width="100%"
            aspect={3}
            className="profiler-chart-container"
        >
            {/* 
                This looks really weird, but this is due to type definitions not describing properly props of this component.
                It supports overflow property, but TypeScript does not detect it, that's why I spread it like so. 
                See: https://github.com/recharts/recharts/issues/1618
            */}
            <BarChart data={pollingStatus} {...{ overflow: 'visible' }}>
                <Tooltip content={<PollingProfilerTooltip mode={mode} />} />
                <CartesianGrid strokeDasharray="3 3" />

                <YAxis
                    unit={unit}
                    label={
                        yAxisTopLabel
                            ? {
                                  value: yAxisTopLabel,
                                  position: 'top',
                                  className: 'profiler-chart-label',
                              }
                            : ''
                    }
                >
                    <Label
                        value={yAxisBottomLabel ? yAxisBottomLabel : ''}
                        position="bottom"
                        className="profiler-chart-label"
                    />
                </YAxis>
                <Bar dataKey={yAxisDataKey}>
                    {pollingStatus?.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={colors[index % colors.length]}
                            stroke="black"
                        />
                    ))}
                </Bar>
            </BarChart>
        </ResponsiveContainer>
    );
}
