import Loading from '@/components/Loading/Loading';
import { useState } from 'react';
import useHostLogs from './useHostLogs';

// Components
import LogViewer from './LogViewer/LogViewer';

/**
 * Fetch and display all logs that belong to the current host
 */
const HostLogs = () => {
    const [page, setPage] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const { value, loading, error } = useHostLogs(page, pageSize);

    const handlePageChange = async (newPage: number, pageSize: number) => {
        setPage(newPage);
        setPageSize(pageSize);
    };

    if (error) return <p>{error.message}</p>;
    else if (loading) return <Loading />;

    return (
        <LogViewer
            response={value}
            fetchHostLogs={handlePageChange}
            isLoading={loading}
        />
    );
};

export default HostLogs;
