// API
import { startServer } from '@/api/tango/host';

// Types
import type { IServerInfo } from '@/api/subscription';

//Context
import { useAppContext } from '@/hooks/state/useAppContext';

// Components
import { AcceptDialog } from '@/components/Dialog/Dialog';
import { notifyError, notifySuccess } from '@/utils/notification';
import {
    createServerInstanceMenuItem,
    type IHostServerDialogProps,
} from './instanceDialogs';

const StartServer = ({ server, isOpen, close }: IHostServerDialogProps) => {
    const { selectedHost } = useAppContext();

    const exec = () => {
        startServer(selectedHost, server.name)
            .then((response) => {
                if (response) notifySuccess(`Started server "${server.name}"`);
            })
            .catch((error: unknown) => {
                if (error instanceof Error) notifyError(error.message);
            });
        close();
    };

    return (
        <AcceptDialog
            message={`Do you really want to start the ${server.name}?`}
            fn={exec}
            isOpen={isOpen}
            close={close}
        />
    );
};

const createStartServer = (server: IServerInfo) =>
    createServerInstanceMenuItem(server, StartServer, null);

export default createStartServer;
