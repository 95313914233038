import { useMemo, useState } from 'react';

// Components
import AutoPaginatedTable from '@/components/Table/AutoPaginatedTable';
import type { ColumnDef } from '@tanstack/react-table';

// MUI
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import IconButton from '@mui/material/IconButton';

// SCSS
import './StatisticsTable.scss';

// Lib
import type { IServerStatistics } from '../ServerStatisticsTable/libServerStatistics';
import { convertRawToServerStatistics } from '../ServerStatisticsTable/libServerStatistics';
import { convertRawToStarterStatistics } from '../StarterStatisticsEntry/libStarterStatisticsEntry';
import { groupStatisticsByServers } from './libStatisticsTable';

// Types
import DurationString from '../DurationString/DurationString';
import type { IRawStarterStatisticsResponse } from '../libHostStarterStatistics';
import PrettyDate from '../PrettyDate/PrettyDate';

interface IStatisticsTableProps {
    rawStatistics: Array<IRawStarterStatisticsResponse>;
}

type ViewModeT = 'StarterStatistics' | 'ServerStatistics';

interface IStarterStatistics {
    server: string;
    runTimeDuration: number;
    failures: number;
    failureDuration: number;
}

/**
 * Display Starter or server statistics.
 *
 * Here we are handling rooting between those two
 * tables as we don't have the global one.
 *
 * @param rawStatistics Array of raw starter statistics
 */
const StatisticsTable = ({ rawStatistics }: IStatisticsTableProps) => {
    const [viewMode, setViewMode] = useState<ViewModeT>('StarterStatistics');

    // If we want to display statistics of particular server, this state
    // indicates which one
    const [selectedServer, setSelectedServer] = useState<string | null>(null);

    const groupedStatistics = groupStatisticsByServers(rawStatistics);

    const goBackToStarterTable = () => {
        setViewMode('StarterStatistics');
        setSelectedServer(null);
    };

    const changeToServerTable = (serverName: string) => {
        setViewMode('ServerStatistics');
        setSelectedServer(serverName);
    };

    const starterColumns = useMemo<ColumnDef<IStarterStatistics>[]>(
        () => [
            {
                accessorKey: 'server',
                header: 'Server',
                cell: (info) => {
                    const serverName = info.getValue<string>();
                    return (
                        <u
                            onClick={() => changeToServerTable(serverName)}
                            style={{ cursor: 'pointer' }}
                        >
                            {serverName}
                        </u>
                    );
                },
            },
            {
                accessorKey: 'runTimeDuration',
                header: 'Run time',
                cell: (info) => (
                    <DurationString seconds={info.getValue<number>()} />
                ),
            },
            {
                accessorKey: 'failures',
                header: 'Failures',
            },
            {
                accessorKey: 'failureDuration',
                header: 'Failure duration',
                cell: (info) => (
                    <DurationString seconds={info.getValue<number>()} />
                ),
            },
        ],
        [],
    );

    const serverColumns = useMemo<ColumnDef<IServerStatistics>[]>(
        () => [
            {
                accessorKey: 'state',
                header: 'State',
            },
            {
                accessorKey: 'beginDateNumber',
                header: 'Begin',
                cell: (info) => (
                    <PrettyDate seconds={info.getValue<number>()} />
                ),
            },
            {
                accessorKey: 'endDateNumber',
                header: 'End',
                cell: (info) => (
                    <PrettyDate seconds={info.getValue<number>()} />
                ),
            },
            {
                accessorKey: 'duration',
                header: 'Duration',
                cell: (info) => (
                    <DurationString seconds={info.getValue<number>()} />
                ),
            },
            {
                accessorKey: 'started',
                header: 'Started',
            },
        ],
        [],
    );

    // Display starter statistics
    if (viewMode === 'StarterStatistics') {
        const starterData = Object.entries(groupedStatistics).map(
            ([, serverStats]) => convertRawToStarterStatistics(serverStats),
        );

        return (
            <div className="starterStatisticsTable">
                <AutoPaginatedTable<IStarterStatistics>
                    columns={starterColumns}
                    data={starterData}
                />
            </div>
        );
    }

    // Display statistics of particular server
    if (viewMode === 'ServerStatistics' && selectedServer) {
        const serverRawStatistics = groupedStatistics[selectedServer];
        const statistics = convertRawToServerStatistics(serverRawStatistics);

        return (
            <div className="serverStatisticsTable">
                <div className="tableHeader">
                    <IconButton onClick={goBackToStarterTable}>
                        <ArrowBackIcon />
                    </IconButton>
                    <div className="serverName">
                        <p>{selectedServer}</p>
                    </div>
                </div>
                <AutoPaginatedTable<IServerStatistics>
                    columns={serverColumns}
                    data={statistics}
                />
            </div>
        );
    }

    // Display nothing, this should never happened.
    // We could use && operator but I think this looks nicer
    return null;
};

export default StatisticsTable;
