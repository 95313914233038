// React
import { useState } from 'react';

// Types
import {
    createServerInstanceMenuItem,
    type IHostServerDialogProps,
} from '../instanceDialogs';
import { ExportDeviceServerForm } from './ExportDeviceServerForm';

// API
import { SERVER_GET_INFO } from '@/api/gql/query';
import { gqlRequest } from '@/api/gql/request';
import type { getServerInfoQueryType } from '@/api/gql/types';
import type { IServerInfo } from '@/api/subscription';
import { putServerInDatabase } from '@/api/tango/server/putServerInDatabase/putServerInDatabase';
import { AcceptDialog } from '@/components/Dialog/Dialog';

// Utils
import { getCurrentDB } from '@/utils/database';
import { notifyError, notifySuccess } from '@/utils/notification';

// MUI
import { deleteOfflineServer } from '@/api/tango/server/deleteOfflineServer.ts/deleteOfflineServer';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

function ExportDeviceServer({ server, isOpen, close }: IHostServerDialogProps) {
    const availableDatabases =
        window.POLKA_CONFIG.databases?.filter(
            (dbName) => dbName !== getCurrentDB(),
        ) || [];
    const [selectedDatabase, setSelectedDatabase] = useState(
        availableDatabases[0],
    );
    const [removeServer, setRemoveServer] = useState(false);
    const [isAcceptDialogOpen, setIsAcceptDialogOpen] = useState(false);

    const putServerInSelectedDatabase = async () => {
        try {
            await putServerInDatabase(
                server.name,
                selectedDatabase,
                removeServer,
            ).then(() => {
                notifySuccess(`${server.name} exported to ${selectedDatabase}`);
            });
        } catch (error: unknown) {
            if (error instanceof Error) notifyError(error.message);
        }
    };

    const handleChangeDatabaseClick = async () => {
        try {
            const [serverName, instance] = server.name.split('/');
            const { servers } = await gqlRequest<getServerInfoQueryType>(
                SERVER_GET_INFO,
                { serverName, instance },
                { tangoDb: selectedDatabase },
            );
            const serverExistsOnSelectedDatabase =
                servers?.map((server) => server.instances).flat().length > 0;

            if (serverExistsOnSelectedDatabase) {
                setIsAcceptDialogOpen(true);
            } else {
                putServerInSelectedDatabase();
                close();
            }
        } catch (error: unknown) {
            if (error instanceof Error) notifyError(`${error}`);
        }
    };

    const onServerOverride = async () => {
        try {
            try {
                await deleteOfflineServer(server.name, selectedDatabase);
            } catch (error: unknown) {
                notifyError(
                    `Cannot override server ${server.name} because it is online.`,
                );
            }
            putServerInSelectedDatabase();

            close();
        } catch (error: unknown) {
            if (error instanceof Error) notifyError(error.message);
        }
    };

    return (
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>{`Export ${
                server.name
            } from ${getCurrentDB()} to another tango database`}</DialogTitle>
            <DialogContent>
                <ExportDeviceServerForm
                    availableDatabases={availableDatabases}
                    selectedDatabase={selectedDatabase}
                    onSelectDatabase={setSelectedDatabase}
                    removeServer={removeServer}
                    onRemoveServerChange={setRemoveServer}
                />
                <AcceptDialog
                    isOpen={isAcceptDialogOpen}
                    close={() => setIsAcceptDialogOpen(false)}
                    message={`${server.name} already exists in ${selectedDatabase}. Do you want to overwrite it?`}
                    fn={onServerOverride}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>CANCEL</Button>
                <Button onClick={handleChangeDatabaseClick} variant="contained">
                    SAVE
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const createExportDeviceServer = (server: IServerInfo) =>
    createServerInstanceMenuItem(server, ExportDeviceServer, null);

export default createExportDeviceServer;
