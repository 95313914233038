import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

type ExportDeviceServerFormProps = {
    availableDatabases: string[];
    selectedDatabase: string;
    onSelectDatabase: (database: string) => void;
    removeServer: boolean;
    onRemoveServerChange: (removeServer: boolean) => void;
};
export function ExportDeviceServerForm({
    availableDatabases,
    selectedDatabase,
    onSelectDatabase,
    removeServer,
    onRemoveServerChange,
}: ExportDeviceServerFormProps) {
    return (
        <Stack direction="column">
            <Autocomplete
                options={availableDatabases}
                onChange={(_, value) => onSelectDatabase(value ?? '')}
                value={selectedDatabase}
                sx={{ marginTop: 1 }}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        label="New database"
                        variant="outlined"
                    />
                )}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={removeServer}
                        onChange={(_, checked) => onRemoveServerChange(checked)}
                    />
                }
                label="Remove server from current database"
            />
        </Stack>
    );
}
