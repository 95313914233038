import './NavbarLogo.scss';

const NavbarLogo = () => {
    const handleLogoClick = () => {
        window.location.href = '/';
    };

    return (
        <div>
            <button className="navbarLogo" onClick={handleLogoClick}>
                Polka
            </button>
        </div>
    );
};

export default NavbarLogo;
