// Components
import CloudNavbar from './CloudNavbar/CloudNavbar';

// Bootstrap
import { Container } from 'react-bootstrap';

//SCSS
import './Cloud.scss';

const Cloud = ({ children }: { children: React.ReactNode }) => {
    return (
        <Container className="cloud">
            <CloudNavbar />
            <div className="cloud-content">{children}</div>
        </Container>
    );
};

export default Cloud;
