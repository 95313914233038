import type { IStarterLogs } from '@/api/tango/host';
import AutoPaginatedTable from '@/components/Table/AutoPaginatedTable';

import { type ColumnDef } from '@tanstack/react-table';
import { useMemo } from 'react';

interface IStarterLogViewerProps {
    starterLogs: IStarterLogs[];
}

/**
 * Display table with all starter logs
 *
 * @param starterLogs - table with all starter logs
 */
const StarterLogViewer = ({ starterLogs }: IStarterLogViewerProps) => {
    const columns = useMemo<ColumnDef<IStarterLogs>[]>(
        () => [
            {
                accessorKey: 'date',
                header: 'Date',
                sortingFn: 'datetime',
                sortDescFirst: true,
            },
            {
                accessorKey: 'time',
                header: 'Time',
                sortDescFirst: true,
            },
            {
                accessorKey: 'message',
                header: 'Message',
                sortUndefined: 'last',
            },
        ],
        [],
    );

    return (
        <AutoPaginatedTable<IStarterLogs>
            columns={columns}
            data={starterLogs}
            emptyMessage="It looks like there is no logs"
        />
    );
};

export default StarterLogViewer;
