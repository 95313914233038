import { gql } from 'graphql-request';

export function createBatchedOperation<TVariables>(
    queryCreator: (variables: TVariables) => string,
    variables: TVariables[],
    operationType: 'mutation' | 'query' = 'query',
    operationName = '',
) {
    if (variables.length === 0) {
        throw new Error(
            `${operationType} ${operationName} must have at least one variable specified `,
        );
    }
    return gql`
        ${operationType} ${operationName} {
            ${variables.map(queryCreator).join(' ')}
        }
    `;
}
