import { getStarterStatistics } from '@/api/tango/host';

export interface IRawStarterStatisticsResponse {
    deviceName: string;
    state: string; // 'ON' | 'FAULT';

    // Time came from C time() function, so it's in seconds
    startedTime: number;
    failureTime: number;

    autoStart: string; // 'true' | 'false';
}

/**
 * Parse response and convert it to readable array of logs
 *
 * Here is example output log string:
 * TangoTest/test\tFAULT\t1731505803\t1731505887\tfalse\n
 * TangoTest/test\ON\t17315058015\t1731505889\tfalse\n
 *
 * Rows are separated by new line( \n ) escape char,
 * columns by tabulation( \t ) escape char.
 *
 * @param responseBody response string
 *
 * @returns Array of logs
 */
export function parseResponseBody(
    responseBody: string,
): Array<IRawStarterStatisticsResponse> {
    const rawStringLogs = responseBody.split('\n').filter((s) => s);

    const rawLogs: Array<IRawStarterStatisticsResponse> = rawStringLogs.map(
        (logString: string) => {
            const [deviceName, state, startedTime, failureTime, autoStart] =
                logString.split('\t');

            const rawLog: IRawStarterStatisticsResponse = {
                deviceName,
                state,

                startedTime: parseInt(startedTime),
                failureTime: parseInt(failureTime),

                autoStart,
            };
            return rawLog;
        },
    );

    return rawLogs;
}

/**
 * Fetch content of Statistics file from given starter
 *
 * @param hostName Starter name
 *
 * @returns Array of raw statistics
 */
export async function fetchStarterStatistics(hostName: string) {
    // Parser response
    const response = await getStarterStatistics(hostName);

    if (!response.ok) {
        const combinedMessage = response.message.join('; ');
        throw new Error(`DevReadLog: ${combinedMessage}`);
    }

    const rawLogString = response.output;
    return parseResponseBody(rawLogString);
}
