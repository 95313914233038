import { gqlRequestCommand } from '@/api/gql/request';

/**
 * Fetch starter statistics by executing "DevReadLog"
 * command
 *
 * @param host Starter name
 *
 * @returns Response object
 */
export async function getStarterStatistics(host: string) {
    const fullHostName = `tango/admin/${host}`;
    const command = 'DevReadLog';
    const argin = 'Statistics';

    const { executeCommand } = await gqlRequestCommand<string>(
        fullHostName,
        command,
        argin,
    );

    return executeCommand;
}
