import {
    REQ_EXECUTE_COMMAND_QUERY,
    REQ_PUT_DEVICE_PROPERTY_QUERY,
} from '@/api/gql/query';
import { request } from 'graphql-request';
// Types
import type {
    IReqExecuteCommandDTO,
    IReqPutDevicePropertyDTO,
} from '@/api/gql/types';
import { getApiUrl, getCurrentDB } from '@/utils/database';
import type { Variables } from 'graphql-request';

import saveLogs from '../mongo/logs/saveLogs';

type tangoGQLError = {
    errors: {
        reason: string;
    }[];
};

// graphql-request errors are long and contain a lot unnecessary
// inforamtion that's why we want to parse it
export function gqlErrorParse(resp: unknown) {
    //TODO: better typing

    const raw = JSON.stringify(resp, undefined, 2);
    const error = JSON.parse(raw);
    const errorMsg = error.response.error; // TODO: examine if tangogql's responses really have this field
    if (errorMsg) return errorMsg;
    const errors = error.response.errors as tangoGQLError['errors'] | undefined;
    if (errors) return errors.map((e) => e.reason).join('\n');
}

type GqlRequestOptions = {
    tangoDb?: string;
    shouldSaveLogs?: boolean;
};

/**
 * Wraper function for graphql-request library
 *
 * @param query
 * @param variables
 */
export async function gqlRequest<RequestType>(
    query: string,
    variables: Variables = {},
    { tangoDb }: GqlRequestOptions = {},
) {
    return await request<RequestType>({
        url: getApiUrl(tangoDb),
        document: query,
        variables: variables,
    }).catch((err) => {
        const gqlError = gqlErrorParse(err);
        throw new Error(gqlError);
    });
}

type gqlCommandVariable = string | number | Array<unknown>;

export async function gqlRequestCommand<TOutput = unknown>(
    device: string,
    command: string,
    argin?: gqlCommandVariable,
    { tangoDb, shouldSaveLogs = true }: GqlRequestOptions = {},
) {
    /*
     * TangoGQL request for exectutin command on device
     *
     * device - device e.g. tango/admin/tangobox
     * command - e.g. DevStart
     * argin - arguments
     */
    const commandResponse = await gqlRequest<IReqExecuteCommandDTO<TOutput>>(
        REQ_EXECUTE_COMMAND_QUERY,
        {
            device,
            command,
            argin,
        },
        { tangoDb },
    );
    if (shouldSaveLogs && commandResponse.executeCommand.ok) {
        const timestamp = new Date();
        await saveLogs({
            actionType: 'ExecuteCommandUserAction',
            timestamp,
            tangoDB:
                tangoDb || getCurrentDB() || window.POLKA_CONFIG.beamline || '', // NOTE may refactor it, because there are many branches to it.
            device,
            name: command,
            argin: String(argin),
        });
    }

    return commandResponse;
}

// TODO: Implement
//export async function gqlRequestAttribute() {}

export function getCommandRespond(resp: IReqExecuteCommandDTO) {
    return resp.executeCommand;
}

export async function gqlPutDeviceProperty(
    device: string,
    name: string,
    value: string[],
    { tangoDb, shouldSaveLogs = true }: GqlRequestOptions = {},
) {
    /*
     * TangoGQL request for setting DeviceProperty
     * device - device e.g. tango/admin/tangobox
     * name - name of the DeviceProperty e.g. HostUsage
     * value - value for the DeviceProperty
     */
    const commandResponse = await gqlRequest<IReqPutDevicePropertyDTO>(
        REQ_PUT_DEVICE_PROPERTY_QUERY,
        {
            device,
            name,
            value,
        },
        { tangoDb },
    );
    if (shouldSaveLogs && commandResponse.putDeviceProperty.ok) {
        const timestamp = new Date();
        await saveLogs({
            actionType: 'PutDevicePropertyUserAction',
            timestamp,
            tangoDB:
                tangoDb || getCurrentDB() || window.POLKA_CONFIG.beamline || '',
            device,
            name,
            argin: value.join(', '),
        });
    }
    // changes in properties are respected by devices only after executing init.
    await gqlRequest<IReqExecuteCommandDTO>(REQ_EXECUTE_COMMAND_QUERY, {
        device,
        command: 'Init',
    });

    return commandResponse;
}
