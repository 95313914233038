import StorageIcon from '@mui/icons-material/Storage';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';

type InvalidDatabaseErrorPageProps = {
    databases: string[];
    currentDatabase?: string;
};

export function InvalidDatabaseErrorPage({
    databases,
    currentDatabase,
}: InvalidDatabaseErrorPageProps) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                p: 3,
                maxWidth: 600,
                mx: 'auto',
            }}
        >
            <StorageIcon
                sx={{
                    fontSize: 48,
                    color: 'error.main',
                    mb: 2,
                }}
            />

            <Typography variant="h4" gutterBottom color="error" align="center">
                {currentDatabase
                    ? `Database Not Found: ${currentDatabase}`
                    : 'No Database Selected'}
            </Typography>

            <Typography
                variant="body1"
                color="text.secondary"
                sx={{ mb: 4 }}
                align="center"
            >
                {currentDatabase
                    ? `The database "${currentDatabase}" is not available. Please select a valid database from the list below.`
                    : 'Please select a database to continue.'}
            </Typography>

            <Typography variant="h6" gutterBottom sx={{ mb: 2 }} align="center">
                Available Databases:
            </Typography>

            <List sx={{ width: '100%' }}>
                {databases.map((db) => (
                    <Button
                        key={db}
                        variant="outlined"
                        fullWidth
                        startIcon={<StorageIcon />}
                        href={`../${db}`}
                        sx={{ mb: 1, justifyContent: 'flex-start' }}
                    >
                        {db}
                    </Button>
                ))}
            </List>
        </Box>
    );
}
