import { notifySuccess } from '@/utils/notification';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { getPollingStatus } from '../getPollingStatus/getPollingStatus';
import {
    removePolling,
    type RemovePollingParams,
} from '../removePolling/removePolling';
import { setPolling, type SetPollingParams } from '../setPolling/setPolling';
import { extractPolledObjectInfo } from '../utils';

export type PolledObjectInfo = ReturnType<typeof extractPolledObjectInfo>;

type UsePollingStatusOptions = {
    deviceServer: string;
    device: string;
    refetchInterval?: number | false;
};

export function usePolling({
    device,
    deviceServer,
    refetchInterval = false,
}: UsePollingStatusOptions) {
    const queryClient = useQueryClient();

    const getPollingStatusQuery = useQuery({
        queryKey: ['getPollingStatus', device, deviceServer],
        queryFn: () => {
            return getPollingStatus({
                device,
                deviceServer,
            });
        },
        refetchInterval,
        enabled: !!device && !!deviceServer,
    });

    const removePollingMutation = useMutation({
        mutationKey: ['removePolling'],
        mutationFn: (params: RemovePollingParams) => removePolling(params),
        onSuccess: (_data, { type, polledObject }) => {
            // causes the polling status to be refetched
            queryClient.invalidateQueries({
                queryKey: ['getPollingStatus', device, deviceServer],
            });
            notifySuccess(
                `Polling has been removed successfully for ${type} ${device}/${polledObject}`,
            );
        },
    });

    const setPollingMutation = useMutation({
        mutationKey: ['setPolling'],
        mutationFn: (params: SetPollingParams) => setPolling(params),
        onSuccess: (_data, { type, polledObject }) => {
            queryClient.invalidateQueries({
                queryKey: ['getPollingStatus', device, deviceServer],
            });
            notifySuccess(
                `Polling has been set successfully for ${type} ${device}/${polledObject}`,
            );
        },
    });

    const pollingStatus = getPollingStatusQuery.data?.map((info) =>
        extractPolledObjectInfo(info, device),
    );

    return {
        pollingStatus,
        ...getPollingStatusQuery,
        removePollingMutation,
        setPollingMutation,
    };
}
