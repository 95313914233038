// Types
import type { IRawStarterStatisticsResponse } from '../libHostStarterStatistics';

export interface IServerStatistics {
    state: string; // "Start" | "Failed"
    beginDateNumber: number;
    endDateNumber: number;
    duration: number;
    started: string; // "Auto" | "On req"
}

/**
 * Convert statistics state to Astro like state string
 *
 * @param state Statistics string
 *
 * @returns Astro like statistics state string
 */
function getStateFromEntry(state: string): string {
    if (state == 'ON') return 'Start';
    else if (state == 'FAULT') return 'Failed';
    else return state;
}

/**
 * Find the index of the next restart in statistics
 * array.
 *
 * @param rawStats The array of raw statistics
 * @param currentIndex The index we start searching from
 *
 * @returns The number of the next index or -1 if not found
 */
function getNextRestartIndex(
    rawStats: Array<IRawStarterStatisticsResponse>,
    currentIndex: number,
) {
    currentIndex--;

    for (; currentIndex >= 0; currentIndex--) {
        const statisticsEntry = rawStats[currentIndex];
        if (statisticsEntry.state == 'ON') return currentIndex;
    }
    return -1;
}

/**
 * Find the index of the next failure in statistics
 * array.
 *
 * @param rawStats The array of raw statistics
 * @param currentIndex The index we start searching from
 *
 * @returns The number of the next index or -1 if not found
 */
function getNextFailureIndex(
    rawStats: Array<IRawStarterStatisticsResponse>,
    currentIndex: number,
) {
    currentIndex--;

    for (; currentIndex >= 0; currentIndex--) {
        const statisticsEntry = rawStats[currentIndex];
        if (statisticsEntry.state == 'FAULT') return currentIndex;
    }
    return -1;
}

/**
 * Count the duration between TWO statistics.
 *
 * @param serverStatistics
 * @param currentIndex
 *
 * @returns Duration in seconds
 */
export function countDurationToTillNextStatistic(
    serverStatistics: Array<IRawStarterStatisticsResponse>,
    currentIndex: number,
): number {
    let t0 = 0;
    let t1 = 0;

    const currStatistic = serverStatistics[currentIndex];

    // Check for running time
    if (currStatistic.state == 'ON') {
        t0 = currStatistic.startedTime;

        // Last record (until now)
        if (currentIndex == 0) {
            // The time we from Starter is in seconds, but JS return time is ms
            t1 = ~~(new Date().getTime() / 1000);
        } else {
            let idx = getNextFailureIndex(serverStatistics, currentIndex);

            // Found next failure
            if (idx >= 0) {
                t1 = serverStatistics[idx].failureTime;
                currentIndex = idx + 1;
            }

            // Found next restart
            else {
                idx = getNextRestartIndex(serverStatistics, currentIndex);
                if (idx >= 0) {
                    t1 = serverStatistics[idx].startedTime;
                    currentIndex = idx + 1;
                }
            }
        }
        return t1 - t0;
    }

    // Check for failures
    else if (currStatistic.state == 'FAULT') {
        t0 = currStatistic.failureTime;

        // Last record (until now)
        if (currentIndex == 0) {
            // The time we from Starter is in seconds, but JS return time is ms
            t1 = ~~(new Date().getTime() / 1000);
        } else {
            let idx = getNextRestartIndex(serverStatistics, currentIndex);

            // Found next restart
            if (idx >= 0) {
                const nextRestartEntry = serverStatistics[idx];
                t1 = nextRestartEntry.startedTime;
                currentIndex = idx + 1;
            }

            // Found next failure
            else {
                idx = getNextFailureIndex(serverStatistics, currentIndex);
                if (idx >= 0) {
                    const nextFailEntry = serverStatistics[idx];
                    t1 = nextFailEntry.failureTime;
                    currentIndex = idx + 1;
                }
            }
        }
        return t1 - t0;
    }

    return 0;
}

/**
 * Convert auto start indicator to Astor like string
 *
 * @param autoStart Statistics auto start string
 * @returns Astro like autostart string
 */
function getStartedStringFromEntry(autoStart: string): string {
    if (autoStart == 'true') return 'Auto';
    else if (autoStart == 'false') return 'On req';
    else return autoStart;
}

/**
 * Convert raw statistics from response to server statistics
 *
 * @param rawStatistics Statistics we process
 *
 * @returns Array of ready to use server statistics
 */
export function convertRawToServerStatistics(
    rawStatistics: Array<IRawStarterStatisticsResponse>,
): Array<IServerStatistics> {
    const serverStatistics: Array<IServerStatistics> = [];

    for (let i = rawStatistics.length - 1; i >= 0; i--) {
        const rawStat = rawStatistics[i];

        // Collect all the things...
        const state = getStateFromEntry(rawStat.state);
        const beginDateNumber = rawStat.startedTime;
        const duration = countDurationToTillNextStatistic(rawStatistics, i);
        const started = getStartedStringFromEntry(rawStat.autoStart);

        const endDateNumber = beginDateNumber + duration;

        // And create object...
        const serverStatisticEntry: IServerStatistics = {
            state,
            beginDateNumber,
            endDateNumber,
            duration,
            started,
        };
        serverStatistics.push(serverStatisticEntry);
    }

    return serverStatistics.reverse();
}
