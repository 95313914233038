import { listify } from 'radash';
import React from 'react';

// Context
import { useSession } from '@/context/Session';
import { useAppContext } from '@/hooks/state/useAppContext';

// Bootstrap
import { Col, Row } from 'react-bootstrap';

// SCSS
import './TabNavbar.scss';

export interface ITabEntry {
    component: React.ElementType;
    requireAuth?: boolean;
}

export interface ITabTemplate {
    [tabName: string]: ITabEntry;
}

export const TabNavbar = ({ tabList }: { tabList: ITabTemplate }) => {
    const { selectedTab, setSelectedTab, selectedHost } = useAppContext();
    const session = useSession();

    return (
        <>
            <Row>
                <Col>
                    <h2>{selectedHost}</h2>
                </Col>
            </Row>
            <Row className="tabNavbar-tabs">
                <Col>
                    {listify(tabList, (tabName: string, value: ITabEntry) => {
                        if (value.requireAuth && !session.isLoggedIn) return;
                        const isCurrentTab =
                            tabName === selectedTab ? 'tabNavbar-current' : '';
                        const onClick = () => setSelectedTab(tabName);

                        return (
                            <button
                                key={tabName}
                                className={`tabNavbar-button ${isCurrentTab}`}
                                onClick={onClick}
                            >
                                {tabName}
                            </button>
                        );
                    })}
                </Col>
            </Row>
        </>
    );
};
