import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import PathLabel from '../PathLabel/PathLabel';
import './PathsInput.scss';

type PathsInputProps = {
    paths: Set<string>;
    onChangePaths: (paths: Set<string>) => void;
    error?: string;
};

function PathsInput({ paths, onChangePaths, error }: PathsInputProps) {
    const [currentPath, setCurrentPath] = useState<string>('');

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Enter' && currentPath !== '') {
            onChangePaths(new Set(paths).add(currentPath));
            setCurrentPath('');
        }
    };

    const handleBlur = () => {
        if (currentPath !== '') {
            onChangePaths(new Set(paths).add(currentPath));
            setCurrentPath('');
        }
    };

    const handleDeletePath = (path: string) => {
        const newPaths = new Set(paths);
        newPaths.delete(path);
        return () => onChangePaths(newPaths);
    };

    const pathLabels = [...paths].map((path) => (
        <PathLabel
            key={path}
            pathName={path}
            onDelete={handleDeletePath(path)}
        />
    ));

    return (
        <div>
            <TextField
                onChange={(event) => setCurrentPath(event.target.value)}
                value={currentPath}
                label="Device Server Path"
                placeholder="Path, e.g., /usr/bin"
                onKeyDown={handleKeyDown}
                error={!!error}
                helperText={error}
                onBlur={handleBlur}
                className="paths-input-container"
            />
            <List className="paths-list">{pathLabels}</List>
        </div>
    );
}

export default PathsInput;
