import { FoundType, search } from '@/utils/search/search';

// Context
import { useServerList } from '@/context/ServerListContext';
import { useAppContext } from '@/hooks/state/useAppContext';

import { changeContext, validateInput } from '../libSearchBar';

const useSearchBar = () => {
    const { setSelectedHost, setSearch, setSelectedTab } = useAppContext();

    const serverHostList = useServerList();

    function searchFunction(input: string) {
        validateInput(input);
        const searchedData = search(input, serverHostList);
        changeContext(searchedData, setSelectedHost, setSelectedTab, setSearch);

        const type =
            searchedData.type === FoundType.SERVER
                ? searchedData.server
                : searchedData.host;

        return `${type} found`;
    }

    return searchFunction;
};

export default useSearchBar;
