import type { stateType } from '../tango/types';

/* Extract the state part from the tuple-like string
 * Handle both formats:
 * - "('UNKNOWN', {...})"
 * - "(tango._tango.DevState.ON, {...})"
 */
export function extractStateFromRawString(rawState: string): stateType {
    try {
        const stateMatch = rawState.match(
            /\(?(?:tango\._tango\.DevState\.)?([A-Z]+)(?:['"]?,|\)|$)/,
        );
        if (stateMatch && stateMatch[1]) {
            return stateMatch[1] as stateType;
        }
        return 'UNKNOWN';
    } catch {
        return 'UNKNOWN';
    }
}
