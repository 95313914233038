import { useDeviceData } from '@/context/DeviceData';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import Divider from '@mui/material/Divider';
import FormControl from '@mui/material/FormControl';
import IconButton from '@mui/material/IconButton';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { getBranches } from '../DeviceList/BranchList/libBranchList';

type HostChangeBranchProps = {
    branch: string;
    onChangeBranch: (newBranch: string) => void;
    error?: string;
};

function HostChangeBranch({
    branch,
    onChangeBranch,
    error,
}: HostChangeBranchProps) {
    const [isCustomBranch, setIsCustomBranch] = useState(false);
    const { hostList } = useDeviceData();
    const branchNames = Object.keys(getBranches(hostList));
    const branchOptions = branchNames.map((branchName) => (
        <MenuItem key={branchName} value={branchName}>
            {branchName}
        </MenuItem>
    ));

    return (
        <FormControl style={{ width: '100%' }}>
            {isCustomBranch ? (
                <TextField
                    label="New branch name"
                    value={branch}
                    onChange={(event) =>
                        onChangeBranch(event.currentTarget.value)
                    }
                    error={!!error}
                    helperText={error}
                    InputProps={{
                        endAdornment: (
                            <IconButton
                                onClick={() => {
                                    setIsCustomBranch(false);
                                    onChangeBranch('');
                                }}
                                edge="end"
                                size="small"
                            >
                                <CloseIcon />
                            </IconButton>
                        ),
                    }}
                />
            ) : (
                <>
                    <Select
                        value={branch}
                        onChange={(event) => onChangeBranch(event.target.value)}
                        displayEmpty
                        renderValue={(value) =>
                            value === '' ? 'Select branch' : value
                        } // MUI Select does not support placeholder or label for empty value.
                    >
                        {branchOptions}
                        <Divider sx={{ my: 1 }} />
                        <MenuItem
                            value=""
                            onClick={() => {
                                setIsCustomBranch(true);
                            }}
                            sx={{
                                color: 'primary.main',
                                fontWeight: 'bold',
                            }}
                        >
                            <AddIcon sx={{ mr: 1 }} /> Create New Branch
                        </MenuItem>
                    </Select>
                </>
            )}
        </FormControl>
    );
}

export default HostChangeBranch;
