import { gqlRequestCommand } from '@/api/gql/request';
import { getCurrentDB } from '@/utils/database';
import { getDbName } from '../../database/getDbName/getDbName';

export async function deleteServer(
    serverName: string,
    database = getCurrentDB(),
) {
    const databaseds = await getDbName(database);
    const result = await gqlRequestCommand(
        databaseds,
        'DbDeleteServer',
        serverName,
        {
            tangoDb: database,
        },
    );
    if (!result.executeCommand.ok) {
        throw new Error(result.executeCommand.message[0]);
    }
}
