import type { PolledObjectInfo } from '@/api/tango/server/polling/usePolling/usePolling';
import Typography from '@mui/material/Typography';
import type { TooltipProps } from 'recharts';
import './PollingProfilerTooltip.scss';

type PollingProfilerTooltipProps = TooltipProps<number, string> & {
    mode: 'Duration' | 'History' | 'Drifts';
};

export function PollingProfilerTooltip({
    active,
    payload,
    mode,
}: PollingProfilerTooltipProps) {
    if (active && payload && payload.length) {
        const data = payload[0].payload as PolledObjectInfo;
        const { name, type, raw, lastReadingDuration, lastDrift, drifts } =
            data;
        const titleText =
            mode === 'Duration'
                ? `${type} ${name} : ${lastReadingDuration} ms`
                : `${type} ${name}: ${lastDrift}`;
        return (
            <div className="polling-profiler-tooltip">
                <Typography variant="h6">{titleText}</Typography>
                <div className="polling-profiler-tooltip-details">
                    {raw.split('\n').map((line, index) => (
                        <Typography key={index} variant="body2">
                            {line}
                        </Typography>
                    ))}
                    {mode === 'Drifts' && (
                        <Typography variant="body2">
                            {`Drifts: ${drifts.join(', ')}`}
                        </Typography>
                    )}
                </div>
            </div>
        );
    }

    return null;
}
