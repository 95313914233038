import { groupServersByLevel } from './libLevelPanel';
import { useLevelList } from './useLevelList';

// Types
import type { IServerInfo } from '@/api/subscription';

// Components
import { Row } from 'react-bootstrap';
import LevelGrid from '../LevelGrid/LevelGrid';

// SCSS
import './LevelPanel.scss';

interface ILevelPanelProps {
    serverList: IServerInfo[];
}

const LevelPanel = ({ serverList }: ILevelPanelProps) => {
    const LevelMap = groupServersByLevel(serverList);

    const { expandedLevels, expandAll, collapseAll, setLevelExpanded } =
        useLevelList();

    return (
        <div className="levelPanel">
            <div>
                <button className="levelPanel-button" onClick={expandAll}>
                    Display all
                </button>
                <button className="levelPanel-button" onClick={collapseAll}>
                    Collapse all
                </button>
            </div>
            <Row className="levelPanel-grid">
                <LevelGrid
                    levelMap={LevelMap}
                    levelListsState={expandedLevels}
                    setListOpen={setLevelExpanded}
                />
            </Row>
        </div>
    );
};

export default LevelPanel;
