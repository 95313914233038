// Types
import type { IRawStarterStatisticsResponse } from '../libHostStarterStatistics';

export interface IStarterStatistics {
    server: string;

    // Runtime duration is seconds
    runTimeDuration: number;

    failures: number;

    // Failure duration in seconds
    failureDuration: number;
}

/**
 * Find the index of the next restart in statistics
 * array.
 *
 * @param rawStats The array of raw statistics
 * @param currentIndex The index we start searching from
 *
 * @returns The number of the next index or -1 if not found
 */
function getNextRestartIndex(
    rawStats: Array<IRawStarterStatisticsResponse>,
    currentIndex: number,
) {
    currentIndex--;

    for (; currentIndex >= 0; currentIndex--) {
        const statisticsEntry = rawStats[currentIndex];
        if (statisticsEntry.state == 'ON') return currentIndex;
    }
    return -1;
}

/**
 * Find the index of the next failure in statistics
 * array.
 *
 * @param rawStats The array of raw statistics
 * @param currentIndex The index we start searching from
 *
 * @returns The number of the next index or -1 if not found
 */
function getNextFailureIndex(
    rawStats: Array<IRawStarterStatisticsResponse>,
    currentIndex: number,
) {
    currentIndex--;

    for (; currentIndex >= 0; currentIndex--) {
        const statisticsEntry = rawStats[currentIndex];
        if (statisticsEntry.state == 'FAULT') return currentIndex;
    }
    return -1;
}

/**
 * Convert raw statistics from response to Starter statistics
 *
 * @param serverStatistics Raw statistics for particular server
 *
 * @returns Array of ready to use starter statistics
 */
export function convertRawToStarterStatistics(
    serverStatistics: Array<IRawStarterStatisticsResponse>,
): IStarterStatistics {
    // Time intervals
    let t0 = 0;
    let t1 = 0;

    // Astor like statistics
    let failures = 0;
    let failureDuration = 0;
    let runTimeDuration = 0;
    for (let i = serverStatistics.length - 1; i >= 0; i--) {
        const statisticsEntry = serverStatistics[i];

        // Check for running time
        if (statisticsEntry.state == 'ON') {
            t0 = statisticsEntry.startedTime;

            // Last record (until now)
            if (i == 0) {
                // The time we from Starter is in seconds, but JS return time is ms
                t1 = ~~(new Date().getTime() / 1000);
            } else {
                let idx = getNextFailureIndex(serverStatistics, i);

                // Found next failure
                if (idx >= 0) {
                    t1 = serverStatistics[idx].failureTime;
                    i = idx + 1;
                }

                // Found next restart
                else {
                    idx = getNextRestartIndex(serverStatistics, i);
                    if (idx >= 0) {
                        t1 = serverStatistics[idx].startedTime;
                        i = idx + 1;
                    }
                }
            }
            runTimeDuration += t1 - t0;
        }

        // Check for failures
        else if (statisticsEntry.state == 'FAULT') {
            failures++;

            t0 = statisticsEntry.failureTime;

            // Last record (until now)
            if (i == 0) {
                // The time we from Starter is in seconds, but JS return time is ms
                t1 = ~~(new Date().getTime() / 1000);
            } else {
                let idx = getNextRestartIndex(serverStatistics, i);

                // Found next restart
                if (idx >= 0) {
                    const nextRestartEntry = serverStatistics[idx];
                    t1 = nextRestartEntry.startedTime;
                    i = idx + 1;
                }

                // Found next failure
                else {
                    idx = getNextFailureIndex(serverStatistics, i);
                    if (idx >= 0) {
                        const nextFailEntry = serverStatistics[idx];
                        t1 = nextFailEntry.failureTime;
                        i = idx + 1;
                    }
                }
            }
            failureDuration += t1 - t0;
        }
    }

    return {
        // We assume that statistics are sorted by device
        server: serverStatistics[0].deviceName,

        runTimeDuration,
        failures,
        failureDuration,
    };
}
