import { getAllDatabases } from '@/api/tango/database';
import { appStateDispatchContext } from '@/state/AppState';
import { useContext } from 'react';

// Types
import type { IDatabaseInfo } from '@/api/tango/database';
import type { IFetchType } from '@/hooks/useFetch';

export const useDbContext = () => {
    const dispatch = useContext(appStateDispatchContext);
    const dispatchDb = (db: IFetchType<IDatabaseInfo[]>) =>
        dispatch({ type: 'CHANGE_DB_LIST', newDb: db });

    const fetchDb = async () => {
        const dbData: IFetchType<IDatabaseInfo[]> = {
            value: [],
            loading: true,
            error: undefined,
        };

        try {
            dbData.value = await getAllDatabases();
        } catch (error: unknown) {
            if (error instanceof Error) dbData.error = error;
        } finally {
            dbData.loading = false;
        }

        dispatchDb(dbData);
    };

    return { fetchDb };
};
