import { useDeviceData } from '@/context/DeviceData';

// Components
import CloudContent from '@/app/Cloud/CloudContent/CloudContent';
import ErrorMessage from '@/components/ErrorMessage/ErrorMessage';
import Loading from '@/components/Loading/Loading';

/**
 * This component perform querying TangoGQL for the list
 * of Starters. It also gets the list of databases from the
 * app config.
 */
const CloudBody = () => {
    const { error, loading } = useDeviceData();

    if (loading) return <Loading />;
    if (error) return <ErrorMessage error={error.message} />;

    return <CloudContent />;
};

export default CloudBody;
