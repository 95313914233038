import { extractStateFromRawString } from '@/api/utils/stateUtils';
import { isEmpty } from 'radash';

import type { IDatabaseGetHostsDTO } from '@/api/gql/types';
import type { IHostInfo } from './getAllHosts';

export function getAllHostsCreateReadable(
    dto: IDatabaseGetHostsDTO,
): IHostInfo[] {
    return dto.hosts.map((host) => {
        const hostName = host.name.replace('tango/admin/', '');
        const hostState = extractStateFromRawString(host.state);
        const hostBranch = isEmpty(host.branch)
            ? 'Miscellaneous'
            : host.branch[0].value[0];
        const hostUsage = isEmpty(host.usage) ? '' : host.usage[0].value[0];
        const hostStartDsPath = isEmpty(host.startDsPath)
            ? []
            : host.startDsPath[0].value;

        return {
            name: hostName,
            state: hostState,
            branch: hostBranch,
            usage: hostUsage,
            startDsPath: hostStartDsPath,
        };
    });
}
