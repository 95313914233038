// MUI
import { BREAKPOINTS } from '@/data/constants';
import { createTheme, ThemeProvider } from '@mui/material/styles';

// React & Router
import type { ReactElement } from 'react';
import { StrictMode } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

// Query
import {
    MutationCache,
    QueryCache,
    QueryClient,
    QueryClientProvider,
} from '@tanstack/react-query';

// Components
import Notification from '@/components/Notification/Notification';
import { notifyError } from '@/utils/notification';
import Cloud from './Cloud/Cloud';
import CloudBody from './Cloud/CloudBody/CloudBody';
import { DatabaseRouteGuard } from './DatabaseRouteGuard/DatabaseRouteGuard';
import { NotFound } from './NotFound/NotFound';

// Context & Providers
import { SessionProvider } from '@/context/Session';
import StateProvider from '@/context/StateProvider';
import { AppContextProvider } from '@/hooks/state/useAppContext';

// SCSS
import '@/assets/styles/bootstrap.scss';
import 'react-toastify/dist/ReactToastify.css';
import './App.scss';

// Query Client Configuration
const queryClient = new QueryClient({
    queryCache: new QueryCache({
        // when the queryFunction throws an error, we show a toast
        onError: (error) => {
            notifyError(error.message);
        },
    }),
    mutationCache: new MutationCache({
        onError: (error) => {
            notifyError(error.message);
        },
    }),
    defaultOptions: {
        queries: {
            retry: false, // disables retrying api calls by default
        },
    },
});

// Theme Configuration
const theme = createTheme({
    breakpoints: {
        values: BREAKPOINTS,
    },
});

interface IAppStateProviderProps {
    children: ReactElement;
}

/**
 * Component for all global contexts
 */
const AppStateProvider = ({ children }: IAppStateProviderProps) => {
    return (
        <QueryClientProvider client={queryClient}>
            <SessionProvider>
                <AppContextProvider>
                    <StateProvider>{children}</StateProvider>
                </AppContextProvider>
            </SessionProvider>
        </QueryClientProvider>
    );
};

const AppRoutes = () => (
    <Routes>
        <Route
            path="/:databaseName?"
            element={
                <DatabaseRouteGuard>
                    <CloudBody />
                </DatabaseRouteGuard>
            }
        />
        <Route path="*" element={<NotFound />} />
    </Routes>
);

const App = () => {
    return (
        <StrictMode>
            <ThemeProvider theme={theme}>
                <AppStateProvider>
                    <BrowserRouter>
                        <Notification />
                        <div className="app">
                            <Cloud>
                                <AppRoutes />
                            </Cloud>
                        </div>
                    </BrowserRouter>
                </AppStateProvider>
            </ThemeProvider>
        </StrictMode>
    );
};

export default App;
