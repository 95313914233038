import { MAX_LEVELS } from '@/data/constants';
import { useState } from 'react';

interface IUseLevelListReturn {
    expandedLevels: boolean[];
    expandAll: () => void;
    collapseAll: () => void;
    setLevelExpanded: (levelIndex: number, isExpanded: boolean) => void;
}

export const useLevelList = (): IUseLevelListReturn => {
    const [expandedLevels, setExpandedLevels] = useState<boolean[]>(() =>
        Array(MAX_LEVELS).fill(true),
    );

    const expandAll = () => {
        setExpandedLevels(Array(MAX_LEVELS).fill(true));
    };

    const collapseAll = () => {
        setExpandedLevels(Array(MAX_LEVELS).fill(false));
    };

    const setLevelExpanded = (levelIndex: number, isExpanded: boolean) => {
        setExpandedLevels((prev) => {
            const updated = [...prev];
            updated[levelIndex] = isExpanded;
            return updated;
        });
    };

    return {
        expandedLevels,
        expandAll,
        collapseAll,
        setLevelExpanded,
    };
};
