import { isEmpty } from 'radash';

// Types
import type { IFoundInfo } from '@/utils/search/search';
import { FoundType } from '@/utils/search/search';

export function validateInput(input: string) {
    if (isEmpty(input)) throw new Error('Input is empty');
}

/**
 * Change all context for search
 *
 * @param info
 * @param changeHost
 * @param changeTab
 * @param changeSearch
 */
export function changeContext(
    foundInfo: IFoundInfo,
    changeHost: (newHost: string) => void,
    changeTab: (newTab: string) => void,
    changeSearch: (newSearch: { server: string; host: string }) => void,
) {
    const serverName = foundInfo.server;
    const hostName = foundInfo.host;

    if (hostName === null) throw new Error("Server instance isn't exported");

    changeSearch({ server: serverName, host: hostName });

    changeHost(hostName);
    if (foundInfo.type === FoundType.SERVER) changeTab('Servers');

    setTimeout(() => {
        changeSearch({ server: '', host: '' });
    }, 5000);
}
