// React & Form Libraries
import { zodResolver } from '@hookform/resolvers/zod';
import { useQuery } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';

// MUI
import { TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import InputAdornment from '@mui/material/InputAdornment';

// API
import { getPollingTargets } from '@/api/tango/client/getPollingTargets/getPollingTargets';
import { usePolling } from '@/api/tango/server/polling/usePolling/usePolling';

// Local
import {
    PollingConfigFormSchema,
    type TPollingConfigFormSchemaIn,
    type TPollingConfigFormSchemaOut,
} from './PollingConfigForm.schema';
import './PollingConfigForm.scss';

type PollingConfigFormProps = {
    servedDevices: string[];
    serverName: string;
};

/**
 * Form component for configuring polling settings for servers.
 * Allows users to set up polling for either attributes or commands with specified periods.
 */
export function PollingConfigForm({
    servedDevices,
    serverName,
}: PollingConfigFormProps) {
    // We use different type for Input / Output (after validation) of the form, so that we can have a null
    //value for polledObject only during input, but before submitting.
    const { reset, control, watch, handleSubmit } = useForm<
        TPollingConfigFormSchemaIn,
        unknown,
        TPollingConfigFormSchemaOut
    >({
        defaultValues: {
            type: 'attribute',
            deviceName: null,
            polledObject: null,
            period: 3000,
        },
        resolver: zodResolver(PollingConfigFormSchema),
    });

    const deviceName = watch('deviceName');
    const { data, isFetching } = useQuery({
        queryKey: ['getPollingTargets', deviceName],
        queryFn: async () => await getPollingTargets(deviceName),
        enabled: !!deviceName,
    });
    const pollingTargetOptions =
        (watch('type') === 'attribute' ? data?.attributes : data?.commands) ||
        [];

    const onChangeCheckbox = (type: TPollingConfigFormSchemaOut['type']) => {
        reset({
            deviceName: watch('deviceName'),
            polledObject: null, // resets the autocomplete value
            type,
        });
    };

    const { setPollingMutation } = usePolling({
        device: deviceName ?? '',
        deviceServer: serverName,
    });

    return (
        <form
            id="polling-config-form"
            aria-label="Polling config form"
            className="polling-config-form"
            noValidate
            onSubmit={handleSubmit((data) =>
                setPollingMutation.mutate({ ...data, serverName }),
            )}
        >
            <FormGroup row>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={watch('type') === 'attribute'}
                            onChange={() => onChangeCheckbox('attribute')}
                        />
                    }
                    label="Attribute"
                />
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={watch('type') === 'command'}
                            onChange={() => onChangeCheckbox('command')}
                        />
                    }
                    label="Command"
                />
            </FormGroup>
            <Controller
                control={control}
                name="deviceName"
                render={({
                    field: { onChange, value },
                    fieldState: { error },
                }) => (
                    <Autocomplete
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label="device"
                                error={!!error}
                                helperText={
                                    error ? 'Please select a valid device' : ''
                                }
                            />
                        )}
                        onChange={(_, item) => {
                            onChange(item);
                        }}
                        value={value}
                        defaultChecked={false}
                        options={servedDevices}
                    />
                )}
            />
            <Controller
                control={control}
                name="polledObject"
                render={({
                    field: { onChange, value },
                    fieldState: { error },
                }) => (
                    <Autocomplete
                        value={value}
                        onChange={(_, item) => {
                            onChange(item);
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label={watch('type')}
                                error={!!error}
                                helperText={
                                    error ? 'Please select a valid target' : ''
                                }
                            />
                        )}
                        disabled={!deviceName || isFetching}
                        options={pollingTargetOptions}
                    />
                )}
            />
            <Controller
                name="period"
                control={control}
                render={({
                    field: { value, onChange },
                    fieldState: { error },
                }) => (
                    <TextField
                        value={value}
                        onChange={onChange}
                        label="Numeric Input"
                        variant="outlined"
                        type="number"
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                    ms
                                </InputAdornment>
                            ),
                            inputMode: 'numeric',
                        }}
                        error={!!error}
                        helperText={error ? 'Please enter a valid number' : ''}
                    />
                )}
            />
        </form>
    );
}
