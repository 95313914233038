import { gqlRequestCommand } from '@/api/gql/request';

export async function getServedDevices(serverName: string) {
    const result = await gqlRequestCommand(
        `dserver/${serverName}`,
        'QueryDevice',
    );

    if (!result.executeCommand.ok) {
        throw new Error(result.executeCommand.message.join('\n'));
    }
    // It is array of strings in shape: TangoClass::DeviceName. The class prefix will be truncated.
    return (result.executeCommand.output as string[]).map(
        (devWithClass) => devWithClass.split('::')[1],
    );
}
