import type { IServerInfo } from '@/api/subscription';
import { usePolling } from '@/api/tango/server/polling/usePolling/usePolling';
import { useServedDevices } from '@/api/tango/server/useServedDevices/useServedDevices';
import type { IDialogComponentProps } from '@/components/Dialog/Dialog';
import Autocomplete from '@mui/material/Autocomplete';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import List from '@mui/material/List';
import TextField from '@mui/material/TextField';
import { useState } from 'react';
import { createServerInstanceMenuItem } from '../instanceDialogs';
import { PollingDetail } from './PollingDetail';
type PollingStatusProps = IDialogComponentProps & {
    server: IServerInfo;
};

function PollingStatus({ isOpen, close, server }: PollingStatusProps) {
    const [selectedDevice, setSelectedDevice] = useState<string | null>(null);

    const { data: devices } = useServedDevices({ deviceServer: server.name });

    const {
        pollingStatus,
        refetch: refetchPollingStatus,
        removePollingMutation,
    } = usePolling({
        deviceServer: server.name,
        device: selectedDevice || '',
    });

    return (
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>Polling status for: {server.name}</DialogTitle>
            <DialogContent style={{ paddingTop: '.5rem' }}>
                <Autocomplete
                    options={devices || []}
                    value={selectedDevice}
                    onChange={(_, value) => setSelectedDevice(value)}
                    renderInput={(params) => (
                        <TextField {...params} label="Device" />
                    )}
                />

                <List>
                    {selectedDevice
                        ? pollingStatus?.map((polledObjectInfo, index) => (
                              <PollingDetail
                                  key={index}
                                  polledObjectInfo={polledObjectInfo}
                                  serverName={server.name}
                                  deviceName={selectedDevice}
                                  onRemove={removePollingMutation.mutate}
                              />
                          ))
                        : null}
                </List>
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>CANCEL</Button>
                <Button
                    variant="contained"
                    onClick={() => refetchPollingStatus()}
                >
                    REFRESH
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const createPollingStatus = (server: IServerInfo) =>
    createServerInstanceMenuItem(server, PollingStatus, null);

export default createPollingStatus;
