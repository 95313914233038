// Types
import type { IServerList } from '@/context/ServerListContext/ServerListContext';

import { getType } from './libSearch';

export enum FoundType {
    HOST,
    SERVER,
}

export interface IFoundInfo {
    server: string;
    host: string;
    type: FoundType;
}

/**
 * Search for server or host and change state of app
 *
 * @param toSearch host or server to search
 * @param instances list of all instances
 */
export function search(
    toSearch: string,
    serverHostList: IServerList,
): IFoundInfo {
    const searchedType = getType(toSearch);
    switch (searchedType) {
        case FoundType.SERVER: {
            for (const host in serverHostList) {
                const serverList = serverHostList[host];

                for (const server of serverList) {
                    if (server.name === toSearch)
                        return {
                            server: server.name,
                            host: host,
                            type: FoundType.SERVER,
                        };
                }
            }
            throw new Error('Server not found');
        }

        case FoundType.HOST: {
            for (const host in serverHostList)
                if (host === toSearch)
                    return {
                        server: '',
                        host: host,
                        type: FoundType.HOST,
                    };

            throw new Error('Host not found');
        }
    }
}
