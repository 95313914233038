import { useContext, useRef } from 'react';

// Types
import type { IHostInfo } from '@/api/tango/database';

// Context
import { useAppContext } from '@/hooks/state/useAppContext';
import useChangeHost from './useChangeHost';

// Components
import Collapse from '@mui/material/Collapse';
import List from '@mui/material/List';
import { BranchListScrollContext } from '../BranchListUtils';
import HostButton from '../HostButton/HostButton';

interface IBranchHostListProps {
    branchHostList: IHostInfo[];
    isListOpen: boolean;
    isHostSearchedHere: boolean;
}

/**
 * List of all host in branch
 *
 * @param branchHostList list of hosts to display
 * @param isListOpen
 * @param isHostSearchedHere
 */
const BranchHostList = ({
    branchHostList,
    isListOpen,
    isHostSearchedHere,
}: IBranchHostListProps) => {
    const changeHostFn = useChangeHost();
    const {
        selectedHost: hostName,
        search: { host: searchedHost },
    } = useAppContext();

    const didListCollapsed = useRef(false);
    const toggleCollapseIndicator = () =>
        (didListCollapsed.current = !didListCollapsed.current);

    const scroll = useContext(BranchListScrollContext);
    if (isHostSearchedHere && didListCollapsed)
        scroll.scrollToNode(searchedHost);

    return (
        <Collapse
            in={isListOpen}
            timeout="auto"
            addEndListener={toggleCollapseIndicator}
        >
            <List component="div">
                {branchHostList.map((host) => {
                    const isCurrent = hostName === host.name;
                    const isSearched = searchedHost === host.name;

                    return (
                        <HostButton
                            key={host.name}
                            hostName={host.name}
                            hostState={host.state}
                            hostUsage={host.usage}
                            isCurrent={isCurrent}
                            isSearched={isSearched}
                            changeHost={changeHostFn}
                        />
                    );
                })}
            </List>
        </Collapse>
    );
};

export default BranchHostList;
