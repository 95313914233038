interface IStatisticsTimeProps {
    date: Date;
}

/**
 * Display time in a nice way
 *
 * @param date JS Date object
 */
const StatisticsTime = ({ date }: IStatisticsTimeProps) => {
    // Time
    const hour = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const sec = String(date.getSeconds()).padStart(2, '0');

    return (
        <>
            {hour}:{minutes}:{sec}
        </>
    );
};

interface IStatisticsDateProps {
    date: Date;
}

/**
 * Display date in a nice way
 *
 * @param date JS Date object
 */
const StatisticsDate = ({ date }: IStatisticsDateProps) => {
    const monthNames = [
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    // Date
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();

    return (
        <>
            {day} {monthNames[month - 1]} {year}
        </>
    );
};

interface IPrettyDateProps {
    seconds: number;
}

/**
 * Display given date in a nice and pretty way
 *
 * @param seconds The seconds from statistics
 */
const PrettyDate = ({ seconds }: IPrettyDateProps) => {
    const date = new Date(seconds * 1000);

    return (
        <>
            <StatisticsDate date={date} />
            <p style={{ display: 'inline' }}> </p>
            <StatisticsTime date={date} />
        </>
    );
};

export default PrettyDate;
