import { isEmpty, listify } from 'radash';
import React, { useState } from 'react';

import type {
    dialogComponentType,
    IDialogState,
} from '@/components/Dialog/Dialog';

// MUI
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

export type TKebabMenuComponent = dialogComponentType | null;
export type TKebabMenuOnClick = (() => void) | null;

export interface IKebabMenuItem {
    component: TKebabMenuComponent;
    onClick: TKebabMenuOnClick;
    disabled?: boolean;
}

export interface IMenuOptions {
    [optionName: string]: IKebabMenuItem | 'divider';
}

interface IMenuDialogProps {
    dialog: IDialogState;
    setDialog: (newDialog: IDialogState) => void;
}

const MenuDialog = ({ dialog, setDialog }: IMenuDialogProps) => {
    const handleDialogClose = () =>
        setDialog({ isOpen: false, component: null });

    return !isEmpty(dialog.component) && dialog.component
        ? dialog.component({ isOpen: dialog.isOpen, close: handleDialogClose })
        : null;
};

interface IMenuEntriesProps {
    options: IMenuOptions;
    setDialog: (newDialog: IDialogState) => void;
    closeMenu: () => void;
}

const MenuEntries = ({ options, setDialog, closeMenu }: IMenuEntriesProps) => {
    return (
        <>
            {listify(options, (optionName, optionItem) => {
                if (optionItem === 'divider') {
                    return <Divider key={optionName} />;
                }

                const entryFunction = () => {
                    if (optionItem.onClick) optionItem.onClick();

                    setDialog({
                        isOpen: true,
                        component: optionItem.component,
                    });

                    closeMenu();
                };

                return (
                    <MenuItem
                        key={optionName}
                        onClick={entryFunction}
                        disabled={optionItem.disabled ?? false}
                    >
                        {optionName}
                    </MenuItem>
                );
            })}
        </>
    );
};

interface IKebabMenuProps {
    options: IMenuOptions;
}

const KebabMenu = ({ options }: IKebabMenuProps) => {
    const [dialog, setDialog] = useState<IDialogState>({
        isOpen: false,
        component: null,
    });

    const [anchor, setAnchor] = useState<HTMLElement | null>(null);
    const isMenuOpen = Boolean(anchor);

    // Functions to handle menu
    const handleMenuClick = (event: React.MouseEvent<HTMLElement>) =>
        setAnchor(event.currentTarget);
    const handleMenuClose = () => setAnchor(null);

    return (
        <React.Fragment>
            <IconButton onClick={handleMenuClick}>
                <MoreVertIcon />
            </IconButton>
            <Menu anchorEl={anchor} open={isMenuOpen} onClose={handleMenuClose}>
                <MenuEntries
                    options={options}
                    setDialog={setDialog}
                    closeMenu={handleMenuClose}
                />
            </Menu>
            <MenuDialog dialog={dialog} setDialog={setDialog} />
        </React.Fragment>
    );
};

export default KebabMenu;
