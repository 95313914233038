import { gqlRequest } from '@/api/gql/request';
import { type DatabaseCommandOutput } from '@/api/utils/batchedDatabaseOperation/batchedDatabaseOperation';
import { createBatchedOperation } from '@/api/utils/createBatchedOperation/createBatchedOperation';
import { gql } from 'graphql-request';
import { zip } from 'radash';
import { getServedDevices } from '../../getServedDevices/getServedDevices';
import { deviceNameToGQLName } from '../../putServerInDatabase/utils';

type GetAllPollingStatusesOptions = {
    deviceServer: string;
};

export async function getAllPollingStatuses({
    deviceServer,
}: GetAllPollingStatusesOptions) {
    const devices = await getServedDevices(deviceServer);

    const dserver = `dserver/${deviceServer}`;

    const getPollingStatusQueryCreator = (device: string) =>
        gql`
    getPollingStatus${deviceNameToGQLName(
        device,
    )}: executeCommand(device: "${dserver}" command: "DevPollStatus" argin: "${device}") {
        ok
        message
        output
    }
`;

    const query = createBatchedOperation(
        getPollingStatusQueryCreator,
        devices,
        'mutation',
        'GetAllPollingStatuses',
    );
    const response = await gqlRequest<DatabaseCommandOutput<string[]>>(query);
    const results = Object.values(response);

    if (results.every((result) => !result.ok)) {
        throw new Error(
            `Could not fetch polling statuses for any device served by ${deviceServer}`,
        );
    }
    const outputs = results.map((result) => result.output);
    return Object.fromEntries(zip(devices, outputs));
}
