import { useQuery } from '@tanstack/react-query';

// Context
import { useAppContext } from '@/hooks/state/useAppContext';

// Lib
import { fetchStarterStatistics } from './libHostStarterStatistics';

// Components
import Loading from '@/components/Loading/Loading';
import { useMemo } from 'react';
import StatisticsTable from './StatisticsTable/StatisticsTable';

/**
 * Display starter statistics.
 *
 * Generally how does it work?
 *
 * Starter has command called "DevReadLog" which as an argument,
 * gets the name of the file we want to read, but, there are two
 * special arguments that reads special files:
 *
 * - "Starter" - return Starter log file content
 * - "Statistics" - return Starter statistics file content
 *
 * The one that interests us is of course “Statistics”.
 * When execute this command with this argument, it returns
 * the content of the log file, which looks like this:
 *
 * ```
 * TangoTest/test\tFAULT\t1731505803\t1731505887\tfalse\n
 * TangoTest/test\ON\t17315058015\t1731505889\tfalse\n
 * ```
 *
 * NOTE: Server has to be controlled by starter for logs to be recorded. This is very important.
 *
 * Arguments:
 * - First argument is the name of the server.
 *
 * - Second argument, is the recorded state of the server.
 *   It can be “ON” or “FAULT”, nothing else.
 *
 * - Then we have the start date in the number of seconds format.
 *   This time is get using C time() function, if that interests you.
 *
 * - Next, is the time the when failure has been recorded.
 *   Again in seconds.
 *
 * - The last argument indicates whether server has been started automatically
 *   or by external event like user has started server manually.
 *   It can be “true” or “false”, not that these are strings, not boolean values.
 *
 * Then these logs are parsed and processed.
 */
const HostStarterStatistics = () => {
    const { selectedHost } = useAppContext();

    const { data, error, isLoading } = useQuery({
        queryKey: ['HostStarterStatistics'],
        queryFn: async () => {
            return fetchStarterStatistics(selectedHost);
        },
    });

    // Memoize the table to avoid re-rendering
    // TODO: Something is probably causing many components in App.ts to re-render after some interval, likely due to refetching the host and so on.
    // TODO: remove memoization when upper problem is solved
    const memoizedTable = useMemo(() => {
        if (!data) return null;
        return <StatisticsTable rawStatistics={data} />;
    }, [data]);

    if (isLoading) return <Loading />;
    if (error) return <p>{error.message}</p>;
    if (data == undefined) return <></>;

    return memoizedTable;
};

export default HostStarterStatistics;
