// Lib
import { convertSecondsToDuration } from './libDurationString';

interface IDurationStringProps {
    seconds: number;
}

/**
 * Display duration with appropriate postfix
 *
 * @param seconds Number of seconds to convert and display
 */
const DurationString = ({ seconds }: IDurationStringProps) => {
    const duration = convertSecondsToDuration(seconds);

    const daysString = duration.days == 0 ? <></> : <>{duration.days}d</>;
    const hoursString = duration.hours == 0 ? <></> : <>{duration.hours}h</>;

    const minutesString =
        duration.minutes == 0 ? <></> : <>{duration.minutes}min</>;
    const secondsString =
        duration.seconds == 0 ? <></> : <>{duration.seconds}s</>;

    return (
        <>
            {daysString} {hoursString} {minutesString} {secondsString}
        </>
    );
};

export default DurationString;
