// API
import { hardKillServer } from '@/api/tango/host';

// Types
import type { IServerInfo } from '@/api/subscription';

//Context
import { useAppContext } from '@/hooks/state/useAppContext';

// Components
import { AcceptDialog } from '@/components/Dialog/Dialog';
import { notifyError, notifySuccess } from '@/utils/notification';
import {
    createServerInstanceMenuItem,
    type IHostServerDialogProps,
} from './instanceDialogs';

const KillServer = ({ server, isOpen, close }: IHostServerDialogProps) => {
    const { selectedHost } = useAppContext();

    const exec = () => {
        hardKillServer(selectedHost, server.name)
            .then((response) => {
                // TODO: return message
                if (response) notifySuccess(`Killed server "${server.name}"`);
            })
            .catch((error: unknown) => {
                if (error instanceof Error) notifyError(error.message);
            });
        close();
    };

    return (
        <AcceptDialog
            message={`Do you really want to kill ${server.name}?`}
            fn={exec}
            isOpen={isOpen}
            close={close}
        />
    );
};

const createKillServer = (server: IServerInfo) =>
    createServerInstanceMenuItem(server, KillServer, null);

export default createKillServer;
