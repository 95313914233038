// Context
import { useAppContext } from '@/hooks/state/useAppContext';

// Types
import type { ITabTemplate } from './TabNavbar/TabNavbar';

// Components
import AddHostForm from '@/app/AddHostForm/AddHostForm';
import DbInfo from '@/app/DbInfo/DbInfo';
import HostAddServer from '@/app/HostAddServer/HostAddServer';
import HostEditProperties from '@/app/HostEditProperties/HostEditProperties';
import HostInfo from '@/app/HostInfo/HostInfo';
import HostLogs from '@/app/HostLogs/HostLogs';
import HostServers from '@/app/HostServers/HostServers';
import HostStarterLogs from '@/app/HostStarterLogs/HostStarterLogs';
import HostStarterStatistics from '@/app/HostStarterStatistics/HostStarterStatistics';
import { TabNavbar } from './TabNavbar/TabNavbar';

// SCSS
import './ContentPicker.scss';

const databaseTabList: ITabTemplate = {
    'Info': { component: DbInfo },
    'Add Host': { component: AddHostForm, requireAuth: true },
};

const hostTabList: ITabTemplate = {
    'Info': { component: HostInfo },
    'Servers': { component: HostServers },
    'Add Server': { component: HostAddServer, requireAuth: true },
    'Edit Properties': { component: HostEditProperties, requireAuth: true },
    'Logs': { component: HostLogs, requireAuth: true },
    'Starter Logs': { component: HostStarterLogs, requireAuth: true },
    'Statistics': { component: HostStarterStatistics, requireAuth: true },
};

/**
 * Show current picked page
 *
 * TODO: There should be routing instead of this component
 */
const ContentPicker = () => {
    const { selectedTab, selectedHost } = useAppContext();

    const tabList = selectedHost === 'Database' ? databaseTabList : hostTabList;

    const CurrTab = tabList[selectedTab].component;
    return (
        <div className="contentPicker">
            <TabNavbar tabList={tabList} />
            <div className="contentPicker-tab">
                <CurrTab />
            </div>
        </div>
    );
};

export default ContentPicker;
