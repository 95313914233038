import type { IServerInfo } from '@/api/subscription';
import { useServedDevices } from '@/api/tango/server/useServedDevices/useServedDevices';
import Loading from '@/components/Loading/Loading';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import {
    createServerInstanceMenuItem,
    type IHostServerDialogProps,
} from '../instanceDialogs';
import { PollingConfigForm } from './PollingConfigForm/PollingConfigForm';

function PollingConfigurator({
    server,
    isOpen,
    close,
}: IHostServerDialogProps) {
    const { data: devices, isLoading } = useServedDevices({
        deviceServer: server.name,
    });

    return (
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>{`Manage polling configuration for ${server.name}`}</DialogTitle>
            <DialogContent>
                {isLoading ? (
                    <Loading />
                ) : (
                    <PollingConfigForm
                        servedDevices={devices || []}
                        serverName={server.name}
                    />
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={close}>CANCEL</Button>
                <Button
                    type="submit"
                    variant="contained"
                    form="polling-config-form"
                >
                    SAVE
                </Button>
            </DialogActions>
        </Dialog>
    );
}

const createPollingConfigurator = (server: IServerInfo) =>
    createServerInstanceMenuItem(server, PollingConfigurator, null);

export default createPollingConfigurator;
