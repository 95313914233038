/**
 * Component which informs user that the page they are looking for does not exist
 */
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

export function NotFound() {
    const navigate = useNavigate();

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
                p: 3,
                maxWidth: 600,
                mx: 'auto',
            }}
        >
            <ErrorOutlineIcon
                sx={{
                    fontSize: 64,
                    color: 'error.main',
                    mb: 2,
                }}
            />

            <Typography variant="h3" gutterBottom color="error" align="center">
                404: Page Not Found
            </Typography>

            <Typography
                variant="body1"
                color="text.secondary"
                sx={{ mb: 4 }}
                align="center"
            >
                The page you are looking for might have been removed, had its
                name changed, or is temporarily unavailable.
            </Typography>

            <Button
                variant="contained"
                onClick={() => navigate('/')}
                sx={{ minWidth: 200 }}
            >
                Go to Homepage
            </Button>
        </Box>
    );
}
