// Types
import type { stateType } from '@/api/tango/types';
import type { ILevelPreview } from '../Level/libLevel';

// Components
import StatusDot from '@/components/StatusDot/StatusDot';
import LevelActionBoard from '../LevelActionBoard/LevelActionBoard';
import LevelPreview from '../LevelPreview/LevelPreview';

// MUI
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';

// SCSS
import RequireAuth from '@/app/RequireAuth/RequireAuth';
import './LevelHeader.scss';

interface ILevelHeaderProps {
    level: number;
    levelState: stateType;
    isSearched: boolean;
    toggleList: () => void;
    levelPreview: ILevelPreview;
}

/**
 * Display level number or not controlled if level is 0, state
 * of level, and preview i.e. amount of running, turned off servers
 * and servers in other states
 *
 * @param level level number(TODO: name of this to change)
 * @param levelState
 * @param isSearched indicate if searched server is here
 * @param isListOpen
 * @param setListOpen function for changing list state
 * @param runningServers amount of running servers
 * @param turnedOff amount of turned off servers
 * @param otherServers amount of servers in other state
 */
const LevelHeader = ({
    level,
    levelState,
    isSearched,
    toggleList,
    levelPreview,
}: ILevelHeaderProps) => {
    // NOTE: Shouldn't we put this function as props isntead of
    // passing isListOpen and setListOpen???
    const levelHeader = level === 0 ? 'Not controlled' : `Level ${level}`;

    // if searched server is here, make a highlight of the header
    const searchedStyle = isSearched ? 'levelHeader-searched' : '';

    return (
        <div className="levelHeader">
            <ListItemButton
                data-testid={levelHeader}
                className={`levelHeader-info ${searchedStyle}`}
                onClick={toggleList}
            >
                <ListItemText>
                    <StatusDot state={levelState} size="medium" />
                    <span className="levelHeader-info-name">{levelHeader}</span>
                </ListItemText>
                <LevelPreview
                    runningServers={levelPreview.running}
                    turnedOffServers={levelPreview.turnedOff}
                    otherServers={levelPreview.other}
                />
            </ListItemButton>
            {level !== 0 && (
                <RequireAuth>
                    <LevelActionBoard level={level} />
                </RequireAuth>
            )}
        </div>
    );
};

export default LevelHeader;
