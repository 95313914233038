import { gqlRequestCommand } from '@/api/gql/request';

type GetPollingStatusOptions = {
    device: string;
    deviceServer: string;
};

/**
 *
 * @param param0 device for which polling config is requested
 * @param param1 deviceServer, to which the device belongs
 * @returns list of polling status for the device. Each polling status is list of strings, in format similar to the following:
 * ```
 * [
 *     "Polled attribute name = ampli",
 *      "Polling period (mS) = 3000",
 *      "Polling ring buffer depth = 10",
 *      "Time needed for the last attributes (ampli + double_scalar) reading (mS) = 0.025",
 *      "Data not updated since 2 S and 272 mS",
 *      "Delta between last records (in mS) = 2999, 3000, 3000, 3000"
 * ]
 * ```
 */
export async function getPollingStatus({
    device,
    deviceServer,
}: GetPollingStatusOptions) {
    const result = await gqlRequestCommand<string[] | null>(
        `dserver/${deviceServer}`,
        'DevPollStatus',
        device,
    );
    // the output is an array consisting of strings, which are delimited by newlines.
    if (!result.executeCommand.ok) {
        throw new Error(result.executeCommand.message.join('\n'));
    }
    return result.executeCommand.output ?? [];
}
