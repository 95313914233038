import { gql } from 'graphql-request';

export function deviceNameToGQLName(deviceName: string) {
    // tango device names can contain alphanmeric characters, underscores, dots and slashes, but graphql names
    // can only contain alphanumeric characters and underscores. This will get rid of any character that could
    // cause problems in the graphql query.
    return deviceName.replaceAll(/[^a-zA-Z0-9_]/g, '_');
}

export const getDeviceAttributeList =
    (databaseds: string) => (deviceName: string) =>
        gql`
    getAttributes${deviceNameToGQLName(
        deviceName,
    )}: executeCommand(device: "${databaseds}" command: "DbGetDeviceAttributeList" argin: ["${deviceName}", "*"]) {
        ok
        message
        output
    }
`;

export const getDevicePipeList = (databaseds: string) => (deviceName: string) =>
    gql`
    getPipes${deviceNameToGQLName(
        deviceName,
    )}: executeCommand(device: "${databaseds}" command: "DbGetDevicePipeList" argin: ["${deviceName}", "*"]) {
        ok
        message
        output
    }
`;

export const getDeviceAttributeProperties =
    (databaseds: string) =>
    ([deviceName, attributes]: [string, string[]]) => {
        const argin = JSON.stringify([
            deviceName,
            attributes.length.toString(),
            ...attributes,
        ]);
        return gql`
    getAttrProps${deviceNameToGQLName(
        deviceName,
    )}: executeCommand(device: "${databaseds}" command: "DbGetDeviceAttributeProperty2" argin: ${argin}) {
        ok
        message
        output
    }
`;
    };

export const getDevicePipeProperties =
    (databaseds: string) =>
    ([deviceName, pipes]: [string, string[]]) => {
        const argin = JSON.stringify([
            deviceName,
            pipes.length.toString(),
            ...pipes,
        ]);
        return gql`
    getPipeProps${deviceNameToGQLName(
        deviceName,
    )}: executeCommand(device: "${databaseds}" command: "DbGetDevicePipeProperty" argin: ${argin}) {
        ok
        message
        output
    }
`;
    };

export const putProperty = ([
    deviceName,
    propertyName,
    propertyValue,
]: readonly [string, string, string[]]) => {
    const operationName = `putProperty_${deviceNameToGQLName(
        deviceName,
    )}__${propertyName}`;
    return gql`
    putProperty${operationName}: putDeviceProperty(device: "${deviceName}", name: "${propertyName}", value: ${JSON.stringify(
        propertyValue,
    )}) {
        ok
        message
    }
`;
};

export const putPipeAndAttributeProperties =
    (databaseds: string) =>
    ([deviceName, attributeProperties, pipeProperties]: [
        string,
        string[],
        string[],
    ]) =>
        gql`
    putDeviceAttributeProperty${deviceNameToGQLName(
        deviceName,
    )}: executeCommand(device: "${databaseds}", command: "DbPutDeviceAttributeProperty2",argin: ${JSON.stringify(
            attributeProperties,
        )}) {
        ok
        message
        output
    }
    putDevicePipeProperty${deviceNameToGQLName(
        deviceName,
    )}: executeCommand(device: "${databaseds}", command: "DbPutDevicePipeProperty", argin: ${JSON.stringify(
            pipeProperties,
        )}) {
        ok
        message
        output
    }
`;
