// API
import * as TangoHost from '@/api/tango/host';

// Types
import type { IDialogComponentProps } from '@/components/Dialog/Dialog';

// Context
import { useAppContext } from '@/hooks/state/useAppContext';

// Components
import { notifyError, notifySuccess } from '@/utils/notification';
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

export interface IStartAllProps extends IDialogComponentProps {
    level: string;
}

export const StartAll = ({ level, isOpen, close }: IStartAllProps) => {
    const { selectedHost } = useAppContext();

    const exec = () => {
        TangoHost.startLevel(selectedHost, level)
            .then((resp) => {
                if (resp) notifySuccess(`Successfully started level ${level}`);
            })
            .catch((error: unknown) => {
                if (error instanceof Error) notifyError(error.message);
            });
        close();
    };

    return (
        <Dialog open={isOpen} onClose={close}>
            <DialogTitle>Do you really want to start all servers?</DialogTitle>
            <DialogActions>
                <Button onClick={close}>Cancel</Button>
                <Button variant="contained" onClick={exec}>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default StartAll;
