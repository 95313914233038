import packageInfo from '../../package.json';

// Polka
export const POLKA_VERSION = packageInfo.version;
// levels in the level panel
export const MAX_LEVELS = 6;

// Tango
export const DATABASEDS_DEV = 'sys/database/2';

// Taranta auth
export const JWT_COOKIE_NAME = 'taranta_jwt';

// Timings
export const DEFAULT_TIMING = 3000;
export const DB_GET_ALL_DB_TIMING = 10000;
export const DB_GET_HOSTS_TIMING = 10000;
export const DB_GET_ALL_INSTANCES_TIMING = 10000;

// React Bootstrap breakpoints
export const BREAKPOINTS = {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
} as const;
