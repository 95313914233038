import { useCallback } from 'react';

// API
import fetchLogs from '@/api/mongo/logs/fetchLogs';
import { useFetch } from '@/hooks/useFetch';

// Context
import { useAppContext } from '@/hooks/state/useAppContext';

const useHostLogs = (page: number, pageLimit: number) => {
    const { selectedHost } = useAppContext();
    const fullHostName = `tango/admin/${selectedHost}`;

    // Fetch logs
    const fetch = useCallback(
        () => fetchLogs(fullHostName, page, pageLimit),
        [selectedHost, page, pageLimit],
    );
    const { value, loading, error } = useFetch(fetch);

    return { value, loading, error };
};

export default useHostLogs;
