// Components
import { Grid } from '@mui/material';
import CreateServer from './CreateServer/CreateServer';
import StartNew from './StartNew/StartNew';

const HostAddServer = () => {
    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <StartNew />
            </Grid>
            <Grid item xs={6}>
                <CreateServer />
            </Grid>
        </Grid>
    );
};

export default HostAddServer;
