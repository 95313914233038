import { isEmpty, listify } from 'radash';

// Types
import type { IHostInfo } from '@/api/tango/database';

// lib
import { getBranches } from './libBranchList';

// Components
import Branch from './Branch/Branch';

// MUI
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';

// Local context
import { BranchListScrollContext, useScroll } from './BranchListUtils';

interface IBranchListProps {
    hostList: IHostInfo[];
}

/**
 * List all branches
 */
const BranchList = ({ hostList }: IBranchListProps) => {
    const hostRef = useScroll();

    if (isEmpty(hostList)) {
        return (
            <ListItemButton
                id="no-hosts-indicator"
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    borderRadius: '1rem',
                }}
                disableGutters
            >
                <p
                    style={{
                        margin: 0,
                        marginLeft: '1rem',
                        marginRight: '1rem',
                    }}
                >
                    ayy, it looks like there are no Starters here
                </p>
            </ListItemButton>
        );
    } else {
        const allBranches = getBranches(hostList);
        return (
            <List component="div" className="deviceList-list">
                <BranchListScrollContext.Provider value={hostRef}>
                    {listify(
                        allBranches,
                        (branchName: string, hosts: IHostInfo[]) => {
                            return (
                                <Branch
                                    key={branchName}
                                    branchName={branchName}
                                    branchHostList={hosts}
                                />
                            );
                        },
                    )}
                </BranchListScrollContext.Provider>
            </List>
        );
    }
};

export default BranchList;
