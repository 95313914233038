import { debounce } from 'radash';
import { useContext, useState } from 'react';

// API
import { login } from '@/api/auth/login';
import { validateForm } from './libLoginDialog';

import { ChangeSessionContext } from '@/context/Session';

// Notification
import { notifySuccess } from '@/utils/notification';

// Components
import { Alert, Button, Stack, TextField } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

// SCSS
import './LoginDialog.scss';

interface ILoginProps {
    open: boolean;
    setOpen: (open: boolean) => void;
}

const LoginDialog = ({ open, setOpen }: ILoginProps) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [touched, setTouched] = useState({
        username: false,
        password: false,
    });

    const changeCurrentSession = useContext(ChangeSessionContext);

    const handlePromptClose = () => {
        setOpen(false);
        // Reset form state when closing
        setUsername('');
        setPassword('');
        setError('');
        setTouched({ username: false, password: false });
    };

    const handleSubmit = debounce({ delay: 300 }, async () => {
        try {
            validateForm(username, password);
            const userInfo = await login(username, password);
            changeCurrentSession({ isLoggedIn: true, data: userInfo });

            notifySuccess('Successfully logged in');
            handlePromptClose();
        } catch (error: unknown) {
            if (error instanceof Error) setError(error.message);
        }
    });

    const handleKeyPress = (event: React.KeyboardEvent) => {
        if (event.key === 'Enter') {
            handleSubmit();
        }
    };

    const handleBlur = (field: 'username' | 'password') => {
        setTouched((prev) => ({ ...prev, [field]: true }));
    };

    return (
        <Dialog
            open={open}
            onClose={handlePromptClose}
            aria-labelledby="login-dialog-title"
        >
            <DialogContent>
                <FormControl fullWidth>
                    <FormLabel
                        id="login-dialog-title"
                        className="loginDialog-header"
                    >
                        Log in
                    </FormLabel>
                    <Stack spacing={1} sx={{ width: '300px', mt: 2 }}>
                        <TextField
                            className="loginDialog-input"
                            type="text"
                            value={username}
                            onChange={(event) => {
                                setUsername(event.target.value);
                                setError('');
                            }}
                            onBlur={() => handleBlur('username')}
                            onKeyDown={handleKeyPress}
                            label="Username"
                            error={touched.username && !username}
                            helperText={
                                touched.username && !username
                                    ? 'Username is required'
                                    : ' '
                            }
                            fullWidth
                            aria-label="Username input"
                        />
                        <TextField
                            className="loginDialog-input"
                            type="password"
                            value={password}
                            onChange={(event) => {
                                setPassword(event.target.value);
                                setError('');
                            }}
                            onBlur={() => handleBlur('password')}
                            onKeyDown={handleKeyPress}
                            label="Password"
                            error={touched.password && !password}
                            helperText={
                                touched.password && !password
                                    ? 'Password is required'
                                    : ' '
                            }
                            fullWidth
                            aria-label="Password input"
                        />
                        {error && (
                            <Alert severity="error" role="alert">
                                {error}
                            </Alert>
                        )}
                        <Button
                            variant="contained"
                            onClick={handleSubmit}
                            fullWidth
                        >
                            Log in
                        </Button>
                    </Stack>
                </FormControl>
            </DialogContent>
        </Dialog>
    );
};

export default LoginDialog;
