import {
    getCoreRowModel,
    getPaginationRowModel,
    getSortedRowModel,
    useReactTable,
    type ColumnDef,
    type PaginationState,
    type SortingState,
} from '@tanstack/react-table';
import { useState } from 'react';

import BaseTable, { DEFAULT_PAGE_SIZE } from './BaseTable';

interface IAutoPaginatedTableProps<T> {
    /** Column definitions specifying how to render and handle each column */
    columns: ColumnDef<T>[];
    /** Complete dataset to be paginated client-side */
    data: T[];
    /** Message to display when there are no rows in the table */
    emptyMessage?: string;
}

/** Table component that implements client-side pagination and sorting.
 * All data is managed internally, making it suitable for smaller datasets
 * that can be loaded entirely on the client.
 */
const AutoPaginatedTable = <T extends object>(
    props: IAutoPaginatedTableProps<T>,
): JSX.Element => {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [pagination, setPagination] = useState<PaginationState>({
        pageIndex: 0,
        pageSize: DEFAULT_PAGE_SIZE,
    });

    const table = useReactTable({
        data: props.data,
        columns: props.columns,
        state: {
            sorting,
            pagination,
        },
        onSortingChange: setSorting,
        onPaginationChange: setPagination,
        getCoreRowModel: getCoreRowModel(),
        getPaginationRowModel: getPaginationRowModel(),
        getSortedRowModel: getSortedRowModel(),
    });

    return (
        <BaseTable
            table={table}
            columns={props.columns}
            emptyMessage={props.emptyMessage}
        />
    );
};

export default AutoPaginatedTable;
