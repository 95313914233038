import type {
    PolledObjectType,
    RemovePollingParams,
} from '@/api/tango/server/polling/removePolling/removePolling';
import type { PolledObjectInfo } from '@/api/tango/server/polling/usePolling/usePolling';
import { AcceptDialog } from '@/components/Dialog/Dialog';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import { useState } from 'react';

type PollingDetailProps = {
    polledObjectInfo: PolledObjectInfo;
    serverName: string;
    deviceName: string;
    onRemove: (params: RemovePollingParams) => void;
};

export function PollingDetail({
    polledObjectInfo,
    serverName,
    deviceName,
    onRemove,
}: PollingDetailProps) {
    /**
     * PollingDetail component.
     * It displays the information about the polling status of an attribute or command.
     * The way that these details are extracted, it makes it difficult to identify name and type of polled object
     * hence, these details are extracted using pattern matching of the detail string.
     * @param detailText - the text that contains the polling details.
     *      Formatted like this: "Polled {type} name = {polledObject}\n {rest of the details, separated by newlines}"
     */
    const [listOpen, setListOpen] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);
    const details = polledObjectInfo.raw.split('\n').slice(1);
    const { type, name } = polledObjectInfo;
    return (
        <>
            <ListItemButton onClick={() => setListOpen((isOpen) => !isOpen)}>
                <Typography variant="h5">
                    {type} {name}
                </Typography>
            </ListItemButton>
            <Collapse in={listOpen}>
                {details.map((detail, index) => (
                    <ListItem key={index}>{detail}</ListItem>
                ))}
                <ListItem>
                    <Button
                        onClick={() => setDialogOpen(true)}
                        variant="contained"
                        color="warning"
                    >
                        REMOVE
                    </Button>
                </ListItem>
            </Collapse>
            <AcceptDialog
                message={`Do you really want to remove polling for ${type} ${name}?`}
                fn={() =>
                    onRemove({
                        serverName,
                        deviceName,
                        type: type as PolledObjectType,
                        polledObject: name,
                    })
                }
                isOpen={dialogOpen}
                close={() => setDialogOpen(false)}
            />
        </>
    );
}
