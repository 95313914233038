import { useCallback } from 'react';

import getStarterLogs from '@/api/tango/host/getStarterLogs/getStarterLogs';

import { useFetch } from '@/hooks/useFetch';

import Loading from '@/components/Loading/Loading';
import { useAppContext } from '@/hooks/state/useAppContext';
import StarterLogViewer from './StarterLogViewer/StarterLogViewer';

const HostStarterLogs = () => {
    const { selectedHost } = useAppContext();

    const getLogs = useCallback(
        () => getStarterLogs(selectedHost),
        [selectedHost],
    );
    const { value: logs, loading, error } = useFetch(getLogs);

    if (error) return <p>{error.message}</p>;
    else if (loading) return <Loading />;
    else return <StarterLogViewer starterLogs={logs} />;
};

export default HostStarterLogs;
