// Components
import BranchList from './BranchList/BranchList';
import DatabaseList from './DatabaseList/DatabaseList';
import SearchBar from './SearchBar/SearchBar';

// SCSS
import './DeviceList.scss';

import { useDeviceData } from '@/context/DeviceData';
import { Stack } from '@mui/material';

const DeviceList = () => {
    const { databaseList, hostList } = useDeviceData();
    return (
        <Stack className="deviceList">
            <div className="deviceList-searchbar">
                <SearchBar />
            </div>
            <div className="deviceList-databaseList">
                <DatabaseList db={databaseList} />
            </div>
            <div className="deviceList-branchList">
                <BranchList hostList={hostList} />
            </div>
        </Stack>
    );
};

export default DeviceList;
