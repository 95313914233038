const parseObjectInfo = (objectInfo: string) => {
    // extract type and polledObject from the string.
    // The first element of the result is sliced, because it contains the whole string.
    const [type, polledObject] =
        objectInfo.match(/Polled (\w+) name = (\w+)/)?.slice(1) ?? [];
    return { type, polledObject };
};

/**
 * It gets the most important information from the polling status.
 * The information about last reading is not guaranteed to be present in the polling status. In case it is not present, it should be set to 0.
 * @param objectPollingStatus full polling status for a given command / attribute
 * @returns object in the shape of:
 * ```  name: name of object (attribute or command) being polled
 *      type: type of object being polled: attribute or command
 *      pollingPeriod: polling period in milliseconds
 *      lastReadingDuration: time needed for the last reading / command execution in milliseconds
 *      raw: the raw polling status string
 */

export const extractPolledObjectInfo = (
    rawObjectPollingStatus: string,
    device: string,
) => {
    const objectPollingStatusList = rawObjectPollingStatus.split('\n');

    const polledObjectIdRow = objectPollingStatusList[0];
    const { type, polledObject } = parseObjectInfo(polledObjectIdRow);

    const [, rawPollingPeriod] = objectPollingStatusList[1]?.split('=');
    const pollingPeriod = Number.parseInt(rawPollingPeriod ?? 0);

    const [, rawReading] = objectPollingStatusList[3]?.split('=');
    const lastReadingDuration = Number.parseFloat(rawReading ?? 0);

    const DEFAULT_TIME_DELTAS = [0, 0, 0, 0];
    const timeDeltasLine = objectPollingStatusList[5] ?? '';
    const [, rawTimeDeltasString] = timeDeltasLine.split('=');
    const timeDeltas = rawTimeDeltasString
        ? rawTimeDeltasString.split(',').map(Number)
        : DEFAULT_TIME_DELTAS;

    const drifts = timeDeltas.map((delta) => delta - pollingPeriod);

    return {
        type,
        name: polledObject,
        fullName: `${device}/${polledObject}`,
        pollingPeriod,
        lastReadingDuration,
        raw: rawObjectPollingStatus,
        lastDrift: drifts[0],
        drifts,
    };
};
